import { handleGqlResponseBody } from "@/utils/apollo.util";
import { GET_USER_CONNECTIONS_HISTORY_PAGINATION_QUERY } from "@/graphql/queries/connection/get-user-connections-history-pagination.query";
import { DECLINE_CONNECTION_INVITATION_MUTATION } from "@/graphql/mutations/connection/decline-connection-invitation.mutation";
import { GET_USER_CONNECTIONS_PAGINATION_QUERY } from "@/graphql/queries/connection/get-user-connections-pagination.query";
import { CREATE_CONNECTION_INVITATION_MUTATION } from "@/graphql/mutations/connection/create-connection-invitation.mutation";
import { REMOVE_CONNECTION_MUTATION } from "@/graphql/mutations/connection/remove-connection.mutation";
import { ACCEPT_CONNECTION_MUTATION } from "@/graphql/mutations/connection/accept-connection.mutation";
import { CANCEL_CONNECTION_INVITATION_MUTATION } from "@/graphql/mutations/connection/cancel-connection-invitation.mutation";
import { toSnakeCase } from "@/utils/string";
import { GET_USER_CONTACT_SHARINGS_PAGINATION_QUERY } from "@/graphql/queries/connection/get-user-contact-sharings-pagination.query";

const connection = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getConnectionsQuery(_, { skip, take, orderBy, id, search }) {
      orderBy = orderBy.map((o) => ({
        ...o,
        fieldName: toSnakeCase(o.fieldName),
      }));
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_USER_CONNECTIONS_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            id: id ?? this.getters.getCurrentUser.id,
            search,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getConnectionsHistoryQuery(_, { skip, take, orderBy, type, status }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_USER_CONNECTIONS_HISTORY_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            type,
            status,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getContactSharingsQuery(
      _,
      { query, notMarkedAsRead, skip, take, orderBy }
    ) {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_USER_CONTACT_SHARINGS_PAGINATION_QUERY,
          variables: {
            query,
            notMarkedAsRead,
            skip,
            take,
            orderBy,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    // Actions
    async createConnectionInvitation(_, { userId }) {
      await handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: CREATE_CONNECTION_INVITATION_MUTATION,
          variables: {
            userId: userId,
          },
        });
      });
    },
    async removeConnection(_, { userId }) {
      await handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: REMOVE_CONNECTION_MUTATION,
          variables: {
            userId: userId,
          },
        });
      });
    },
    async acceptConnectionInvitation(_, { userId }) {
      await handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: ACCEPT_CONNECTION_MUTATION,
          variables: {
            userId: userId,
          },
        });
      });
    },
    async declineConnectionInvitation(_, { userId }) {
      await handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: DECLINE_CONNECTION_INVITATION_MUTATION,
          variables: {
            userId,
          },
        });
      });
    },
    async cancelConnectionInvitation(_, { userId }) {
      await handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: CANCEL_CONNECTION_INVITATION_MUTATION,
          variables: {
            userId,
          },
        });
      });
    },
  },
};

export default connection;
