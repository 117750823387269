import gql from "graphql-tag";

export const PREVIEW_EXERCISE_MUTATION = gql`
  mutation PreviewExercise(
    $dragTheWords: UpsertDragTheWordsExerciseInput
    $fillInTheBlanks: UpsertFillInTheBlanksExerciseInput
    $findSynonym: UpsertFindSynonymExerciseInput
    $findThePlace: UpsertFindThePlaceExerciseInput
    $markTheWords: UpsertMarkTheWordsExerciseInput
    $multipleChoice: UpsertMultipleChoiceExerciseInput
    $singleChoice: UpsertSingleChoiceExerciseInput
    $trueFalse: UpsertTrueFalseExerciseDataInput
  ) {
    previewExercise(
      data: {
        dragTheWords: $dragTheWords
        fillInTheBlanks: $fillInTheBlanks
        findSynonym: $findSynonym
        findThePlace: $findThePlace
        markTheWords: $markTheWords
        multipleChoice: $multipleChoice
        singleChoice: $singleChoice
        trueFalse: $trueFalse
      }
    ) {
      type
      dragTheWords {
        answer
        data
        images
        text
      }
      fillInTheBlanks {
        answer
        images
        text
      }
      findSynonym {
        answer
        text
      }
      findThePlace {
        answer
        data
        text
      }
      markTheWords {
        answer
        text
      }
      multipleChoice {
        answer
        data
      }
      singleChoice {
        answer
        data
      }
      trueFalse {
        answer
      }
    }
  }
`;
