import gql from "graphql-tag";

export const REFRESH_TOKEN_MUTATION = gql`
  mutation ($refreshToken: String!) {
    refreshUserToken(data: { token: $refreshToken }) {
      accessToken
      refreshToken
      user {
        id
        role
        email
        firstName
        lastName
      }
    }
  }
`;
