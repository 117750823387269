import { handleGqlResponseBody } from "@/utils/apollo.util";
import { GET_USER_ACTIVITY_HISTORY_PAGINATION_QUERY } from "@/graphql/queries/user/activity-history/get-user-activity-history-pagination.query";

const activityHistory = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getUserActivityHistoryQuery(_, { skip, take }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_USER_ACTIVITY_HISTORY_PAGINATION_QUERY,
          variables: {
            skip,
            take,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default activityHistory;
