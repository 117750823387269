import { io } from "socket.io-client";
import { EventEmitter } from "eventemitter3";
import { Logger } from "@/mixins/logger.mixin";

export const SOCKET_BOARD_ELEMENT_CHANGE_TYPE = {
  Add: 1,
  Delete: 2,
  Update: 3,
};

const lessonSocket = {
  state: {
    socket: null,
    eventTransport: new EventEmitter(),
    changedBoardElementsQueue: [],
    isChangedBoardElementsSending: false,
    chatMessageQueue: [],
    isChatMessageQueueSending: false,
    logger: new Logger("STORE:lessonSocket"),
  },
  getters: {
    getLessonEventTransport(state) {
      return state.eventTransport;
    },
  },
  mutations: {
    async onLessonInit(
      state,
      { wsServerUri, boardId, accessKey, accessToken }
    ) {
      state.logger.debug("onLessonInit");

      if (state.socket) {
        return;
      }
      state.socket = io(wsServerUri, {
        autoConnect: true,
        withCredentials: true,
        auth: {
          accessToken,
          boardId,
          accessKey,
        },
        transports: ["websocket"],
      }).connect();

      state.socket.onAny((event, ...args) => {
        state.logger.debug("onLessonInit", "socket", "onAny", event);

        state.eventTransport.emit(`socket.${event}`, ...args);
      });

      state.socket.on("connect", () => {
        state.logger.debug("onLessonInit", "socket", "connect");

        state.eventTransport.emit(`socket.state.connect`, {});
      });

      state.socket.on("disconnect", () => {
        state.logger.debug("onLessonInit", "socket", "disconnect");

        state.eventTransport.emit(`socket.state.disconnect`);
      });

      state.socket.on("unauthorized", () => {
        state.logger.debug("onLessonInit", "socket", "unauthorized");

        state.eventTransport.emit(`socket.state.unauthorized`);
      });

      // Events from event transport
      state.eventTransport.on("toSocket", (eventName, ...args) => {
        // state.logger.debug('onLessonInit', 'toSocket', eventName, ...args)

        state.socket.emit(eventName, ...args);
      });

      const sendElementsChange = () => {
        state.logger.debug("onLessonInit", "sendElementsChange");

        if (state.isChangedBoardElementsSending) return;
        if (state.changedBoardElementsQueue.length === 0) return;

        state.isChangedBoardElementsSending = true;
        const elementChange = state.changedBoardElementsQueue.shift();

        state.socket.emit("lessonBoard.elementsChange", elementChange, () => {
          state.isChangedBoardElementsSending = false;
          sendElementsChange();
        });
      };

      state.socket.on("lessonBoard.joined", () => {
        state.logger.debug("onLessonInit", "socket", "joined");

        sendElementsChange();
      });

      state.eventTransport.on(
        "custom.client.lessonBoard.elementsChange",
        function (changeData) {
          state.changedBoardElementsQueue.push(changeData);
          sendElementsChange();
        }
      );

      // Chat
      const sendChatMessages = () => {
        console.log("sendChatMessages");
        if (state.isChatMessageQueueSending) return;
        if (state.chatMessageQueue.length === 0) return;

        state.isChatMessageQueueSending = true;
        const messageData = state.chatMessageQueue[0];

        console.log("sending");
        state.socket.emit(
          "lessonSchedule.chat.newMessage",
          messageData,
          (result) => {
            console.log("res", result);
            state.eventTransport.emit(
              "custom.lessonSchedule.chat.report",
              result
            );
            state.chatMessageQueue.shift();

            state.isChatMessageQueueSending = false;
            sendChatMessages();
          }
        );
      };

      state.socket.on("lessonSchedule.joined", () => {
        sendChatMessages();
      });

      state.eventTransport.on(
        "custom.client.lessonSchedule.chat.sendMessage",
        function (messageData) {
          state.chatMessageQueue.push(messageData);
          sendChatMessages();
        }
      );
    },
    onLessonDestroy(state) {
      state.logger.debug("onLessonInit", "onLessonDestroy");

      state.socket?.disconnect();
      state.socket = null;
    },
  },
};

export default lessonSocket;
