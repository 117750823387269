import gql from "graphql-tag";

export const GET_CHAT_MESSAGES_PAGINATION_QUERY = gql`
  query ($chatId: Int!, $skip: Int!, $take: Int!, $orderBy: [OrderByInput!]) {
    getChatMessagesPaginated(
      data: {
        chatId: $chatId
        pagination: { skip: $skip, take: $take }
        orderBy: $orderBy
      }
    ) {
      hasMore
      total
      items {
        id
        createdAt
        message
        userId
        user {
          id
          firstName
          lastName
          profilePhotoX64 {
            fullUri
          }
        }
      }
    }
  }
`;
