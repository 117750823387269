export const loadingByKeyLaterInitMixin = {
  data() {
    return {
      loadingByKey: {},
    };
  },
  methods: {
    setLoadingByKey(key, value) {
      this.$set(this.loadingByKey, key, value);
    },
    initLoadingByKey(key, defaultValue = false) {
      this.setLoadingByKey(key, defaultValue);
    },
    initLoadingByKeys(keys) {
      for (const key of keys) {
        this.initLoadingByKey(key);
      }
    },
  },
  computed: {
    getLoadingByKey() {
      return (key) => {
        if (key in this.loadingByKey) return this.loadingByKey[key];
        return false;
      };
    },
  },
};
