<template>
	<v-dialog
			v-model="isVisible"
			max-width="600px"
			persistent
			@keydown.esc="close"
	>
		<template v-if="notification">
			<urgent-notification-lesson-type
					v-if="notification.type === USER_NOTIFICATION_TYPES.Lesson"
					:data="notification"
					@close="close"
			/>
			<urgent-notification-other-type
					v-if="notification.type === USER_NOTIFICATION_TYPES.Other"
					:data="notification"
					@close="close"
			/>
		</template>
	</v-dialog>
</template>

<script>
import UrgentNotificationLessonType from "@/components/profile/notification/urgent/type/UrgentNotificationLessonType.vue";
import { USER_NOTIFICATION_TYPES } from "@/enums/user-notification-type.enum";
import UrgentNotificationOtherType from "@/components/profile/notification/urgent/type/UrgentNotificationOtherType.vue";

export default {
  components: { UrgentNotificationOtherType, UrgentNotificationLessonType },
  data() {
    return {
      isVisible: false,
      notification: null,
    };
  },
  computed: {
    USER_NOTIFICATION_TYPES() {
      return USER_NOTIFICATION_TYPES;
    },
  },
  watch: {
    notification(value) {
      this.isVisible = !!value;
    },
  },
  methods: {
    async open(notification) {
      this.notification = notification;
      await this.$store.dispatch("playNewNotificationSound");
    },
    async close() {
      const notificationId = this.notification?.id;
      const shouldBeMarkedAsSeen = notificationId && !this.notification.seenAt;
      this.notification = null;

      if (!shouldBeMarkedAsSeen) return;

      console.log("closed");
      await this.$store.dispatch("setNotificationSeen", {
        id: notificationId,
      });
      await this.$store.dispatch("sendSeenNotificationIds");
    },
  },
};
</script>
