<template>
	<v-avatar
			:class="{ grouped: grouped }"
			:color="avatarColor"
			:size="size"
			:style="`margin-left: -${!first && grouped ? size * 0.7 : 0}px`"
			class="cursor-default"
	>
		<AvatarInnerComponent
				:first-name="firstName"
				:last-name="lastName"
				:profile-image="profileImage"
				:size="size"
		/>
	</v-avatar>
</template>

<script>
import { getAvatarColor } from "@/utils/generator";
import AvatarInnerComponent from "@/components/profile/AvatarInnerComponent";

export default {
  components: { AvatarInnerComponent },
  props: {
    size: { type: Number, required: false, default: null },
    profileImage: { type: Object, required: false, default: null },
    firstName: { type: String, required: false, default: null },
    lastName: { type: String, required: false, default: null },
    first: {
      type: Boolean,
      required: false,
      default: false,
    },
    grouped: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      avatarColor: getAvatarColor(`${this.firstName} ${this.lastName}`),
    };
  },
};
</script>

<style lang="scss" scoped>
.grouped {
  border: 2px #fff solid !important;
  text-decoration: none;

  &:hover {
    z-index: 9;
  }
}
</style>
