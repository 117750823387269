import gql from "graphql-tag";

export const GET_QUIZ_HISTORY_PAGINATED_QUERY = gql`
  query (
    $skip: Int!
    $take: Int!
    $quizId: Int!
    $userId: Int
    $lessonScheduleId: Int
    $registered: Boolean
  ) {
    getQuizHistoryPaginated(
      data: {
        pagination: { skip: $skip, take: $take }
        quizId: $quizId
        userId: $userId
        registered: $registered
        lessonScheduleId: $lessonScheduleId
      }
    ) {
      hasMore
      total
      items {
        id
        createdAt
        earnedPoints
        maxPoints
        user {
          id
          firstName
          lastName
        }
        nonRegisteredUser {
          id
          telNumber
          fullName
        }
      }
    }
  }
`;
