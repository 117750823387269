import gql from "graphql-tag";

export const GET_USER_NOTIFICATION_PAGINATION_QUERY = gql`
  query ($type: UserNotificationType, $skip: Int!, $take: Int!) {
    getCurrentUserNotifications(
      data: {
        type: $type
        pagination: { skip: $skip, take: $take }
        orderBy: [{ nulls: Last, direction: Desc, fieldName: "created_at" }]
      }
    ) {
      hasMore
      total
      items {
        id
        type
        action
        title
        description
        createdAt
        seenAt
        priorityAt
        priorityEndsAt
        cancelledAt
        interactedUsers {
          id
          firstName
          lastName
          profilePhotoX64 {
            fullUri
          }
        }
        lessonScheduleId
      }
    }
  }
`;
