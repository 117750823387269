import gql from "graphql-tag";

export const GET_USER_BALANCE_TRANSACTIONS_PAGINATION_QUERY = gql`
  query (
    $fromDate: DateString
    $toDate: DateString
    $type: UserBalanceTransactionType
    $skip: Int!
    $take: Int!
    $orderBy: [OrderByInput!]
  ) {
    getCurrentUserBalanceTransactions(
      data: {
        type: $type
        fromDate: $fromDate
        toDate: $toDate
        pagination: { skip: $skip, take: $take }
        orderBy: $orderBy
      }
    ) {
      total
      hasMore
      items {
        id
        balanceBefore
        balanceAfter
        sum
        type
        createdAt
      }
    }
  }
`;
