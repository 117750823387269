import gql from "graphql-tag";

export const GET_USER_BADGES_QUERY = gql`
  query {
    getUserBadges {
      connectionsCount
      messagesCount
      notificationBadges {
        type
        counts
      }
      urgentNotifications {
        id
        type
        action
        title
        description
        createdAt
        seenAt
        priorityAt
        priorityEndsAt
        cancelledAt
        interactedUsers {
          id
          firstName
          lastName
          profilePhotoX64 {
            fullUri
          }
        }
        lessonScheduleId
      }
    }
  }
`;
