import Vue from "vue";
import Vuex from "vuex";
import authentication from "@/store/modules/authentication";
import currentUser from "@/store/modules/current-user";
import loading from "@/store/modules/loading";
import user from "@/store/modules/user";
import connection from "@/store/modules/connection";
import chat from "@/store/modules/chat";
import activityHistory from "@/store/modules/activity-history";
import lesson from "@/store/modules/lesson";
import exercise from "@/store/modules/exercise";
import notification from "@/store/modules/notification";
import lessonSchedule from "@/store/modules/lesson-schedule";
import quiz from "@/store/modules/quiz";
import lessonBoard from "@/store/modules/lesson-board";
import microphone from "@/store/modules/microphone";
import lessonSocket from "@/store/modules/lesson-socket";
import streamVideo from "@/store/modules/stream-video";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    authentication,
    currentUser,
    user,
    loading,
    connection,
    chat,
    activityHistory,
    notification,
    lesson,
    exercise,
    // camera,
    lessonSchedule,
    quiz,
    lessonBoard,
    microphone,
    lessonSocket,
    streamVideo,
  },
});
