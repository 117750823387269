import gql from "graphql-tag";

export const SEARCH_USERS_PAGINATION_QUERY = gql`
  query (
    $query: String!
    $role: UserRole
    $textbookIds: [Int!]
    $interestIds: [Int!]
    $learnOrTeachId: Int
    $speakIds: [Int!]
    $specialityIds: [Int!]
    $skip: Int!
    $take: Int!
    $orderBy: [OrderByInput!]
  ) {
    searchUsers(
      data: {
        query: $query
        role: $role
        textbookIds: $textbookIds
        interestIds: $interestIds
        speakIds: $speakIds
        learnOrTeachId: $learnOrTeachId
        specialityIds: $specialityIds
        pagination: { skip: $skip, take: $take }
        orderBy: $orderBy
      }
    ) {
      total
      hasMore
      items {
        id
        firstName
        lastName
        profilePhotoX64 {
          fullUri
        }
        shortAbout
        role
        wasOnline
        connectionStatus
      }
    }
  }
`;
