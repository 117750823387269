<template>
	<v-card>
		<v-card-title v-html="data.title" />
		<div v-html="data.description" />
		<v-card-actions>
			<v-spacer />
			<v-btn
					class="mr-2"
					color="grey"
					text
					type="button"
					@click="$emit('close', { id: data.id })"
			>
				{{ $t("buttons.ignore") }}
			</v-btn>
			<v-btn
					class="float-end"
					color="secondary"
					outlined
					type="submit"
					@click="join"
			>
				<v-icon>mdi-plus</v-icon>
				{{ $t("buttons.join") }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close", { id: this.data.id });
    },
    async join() {
      this.close();
      await this.$router.push({
        name: "liveLesson",
        params: { lessonScheduleId: this.data.lessonScheduleId },
      });
    },
  },
};
</script>
