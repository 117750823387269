export const errorByKeyLaterInitMixin = {
  data() {
    return {
      errorsByKey: {},
    };
  },
  methods: {
    setErrorsByKey(key, handledErrors) {
      this.$set(this.errorsByKey, key, handledErrors);
    },
    clearErrorsByKey(key) {
      this.setErrorsByKey(key, []);
    },
    initErrorByKey(key) {
      this.setErrorsByKey(key, []);
    },
    initErrorByKeys(keys) {
      for (const key of keys) {
        this.initErrorByKey(key);
      }
    },
  },
  computed: {
    getErrorsByKey() {
      return (key) => {
        if (key in this.errorsByKey) return this.errorsByKey[key];
        return [];
      };
    },
    hasErrorsByKey() {
      return (key) => {
        return this.getErrorsByKey(key).length > 0;
      };
    },
  },
};
