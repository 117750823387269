import { handleGqlResponseBody } from "@/utils/apollo.util";
import { toSnakeCase } from "@/utils/string";
import { GET_EXERCISE_QUERY } from "@/graphql/queries/exercise/get-exercise.query";
import { CREATE_EXERCISE_MUTATION } from "@/graphql/mutations/exercise/create.mutation";
import { UPDATE_EXERCISE_MUTATION } from "@/graphql/mutations/exercise/update.mutation";
import { DELETE_EXERCISE_MUTATION } from "@/graphql/mutations/exercise/delete.mutation";
import { CHANGE_ORDER_EXERCISE_MUTATION } from "@/graphql/mutations/exercise/change-order-attachment.mutation";
import { PREVIEW_EXERCISE_MUTATION } from "@/graphql/mutations/exercise/preview.mutation";
import { GET_EXERCISE_VALIDATORS_QUERY } from "@/graphql/queries/exercise/get-exercise-validators.query";
import { CHECK_EXERCISE_PREVIEW_MUTATION } from "@/graphql/mutations/exercise/check-preview.mutation";
import { GET_QUIZ_ALL_POINTS_QUERY } from "@/graphql/queries/exercise/get-quiz-all-points.query";
import { GET_EXERCISES_ADMIN_PAGINATION_QUERY } from "@/graphql/queries/exercise/get-exercises-admin-pagination.query";
import { GET_EXERCISES_HISTORY_PAGINATED_QUERY } from "@/graphql/queries/exercise/get-exercises-history.query";
import { GET_EXERCISES_PAGINATION_QUERY } from "@/graphql/queries/exercise/get-exercises-pagination.query";
import { RENEW_EXERCISE_AUDIO_MUTATION } from "@/graphql/mutations/audio/renew-exercise-audio.mutation";
import { UPDATE_AUDIO_MUTATION } from "@/graphql/mutations/audio/update-audio.mutation";
import { REMOVE_EXERCISE_AUDIO_MUTATION } from "@/graphql/mutations/audio/remove-exercise-audio.mutation";
import { CREATE_EXERCISE_AUDIO_MUTATION } from "@/graphql/mutations/audio/create-exercise-audio.mutation";

const exercise = {
  state: {
    itemsPerPage: 1,
  },
  getters: {
    getItemsPerPage(state) {
      return state.itemsPerPage;
    },
  },
  mutations: {
    setItemsPerPage(state, itemsPerPage) {
      state.itemsPerPage = itemsPerPage;
    },
  },
  actions: {
    async createExercise(
      _,
      {
        title,
        description,
        errorHint,
        quizId,
        order,
        points,
        isVocabulary,
        audioData,
        audioHidden,
        audioAutoplay,
        dragTheWords,
        fillInTheBlanks,
        findSynonym,
        findThePlace,
        markTheWords,
        multipleChoice,
        singleChoice,
        trueFalse,
      }
    ) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: CREATE_EXERCISE_MUTATION,
          variables: {
            title,
            description,
            errorHint,
            quizId,
            order,
            points,
            isVocabulary,
            audioData,
            audioHidden,
            audioAutoplay,
            dragTheWords,
            fillInTheBlanks,
            findSynonym,
            findThePlace,
            markTheWords,
            multipleChoice,
            singleChoice,
            trueFalse,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async previewExercise(
      _,
      {
        dragTheWords,
        fillInTheBlanks,
        findSynonym,
        findThePlace,
        markTheWords,
        multipleChoice,
        singleChoice,
        trueFalse,
      }
    ) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: PREVIEW_EXERCISE_MUTATION,
          variables: {
            dragTheWords,
            fillInTheBlanks,
            findSynonym,
            findThePlace,
            markTheWords,
            multipleChoice,
            singleChoice,
            trueFalse,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async updateExercise(
      _,
      {
        id,
        title,
        description,
        errorHint,
        points,
        audioHidden,
        audioAutoplay,
        dragTheWords,
        fillInTheBlanks,
        findSynonym,
        findThePlace,
        markTheWords,
        multipleChoice,
        singleChoice,
        trueFalse,
      }
    ) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: UPDATE_EXERCISE_MUTATION,
          variables: {
            id,
            title,
            description,
            errorHint,
            points,
            audioHidden,
            audioAutoplay,
            dragTheWords,
            fillInTheBlanks,
            findSynonym,
            findThePlace,
            markTheWords,
            multipleChoice,
            singleChoice,
            trueFalse,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async checkExercisePreview(_, { data, answer, points, quizId }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: CHECK_EXERCISE_PREVIEW_MUTATION,
          variables: {
            data,
            answer,
            points,
            quizId,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getExerciseValidatorsQuery() {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_EXERCISE_VALIDATORS_QUERY,
          fetchPolicy: "no-cache",
        });
      });
    },
    async getQuizAllPointsQuery(_, { id }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_QUIZ_ALL_POINTS_QUERY,
          variables: { id },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getExerciseQuery(_, { id }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_EXERCISE_QUERY,
          variables: { id },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getExercisesAdminPaginated(
      _,
      { skip, take, orderBy, search, quizId, isVocabulary }
    ) {
      orderBy = orderBy.map((o) => ({
        ...o,
        fieldName: toSnakeCase(o.fieldName),
      }));
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_EXERCISES_ADMIN_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            search,
            quizId,
            isVocabulary,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getExercisesPaginated(_, { skip, take, quizId }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_EXERCISES_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            quizId,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getExercisesHistoryPaginated(
      _,
      { skip, take, quizHistoryId, showCorrectnessOnError }
    ) {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_EXERCISES_HISTORY_PAGINATED_QUERY,
          variables: {
            skip,
            take,
            quizHistoryId,
            showCorrectnessOnError,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async deleteExercise(_, { id }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: DELETE_EXERCISE_MUTATION,
          variables: { id },
          fetchPolicy: "no-cache",
        });
      });
    },
    async changeOrderExercise(_, { first, second }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: CHANGE_ORDER_EXERCISE_MUTATION,
          variables: { first, second },
          fetchPolicy: "no-cache",
        });
      });
    },
    async createExerciseAudio(_, { exerciseId, title, file }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: CREATE_EXERCISE_AUDIO_MUTATION,
          variables: { exerciseId, title, file },
          fetchPolicy: "no-cache",
        });
      });
    },
    async renewExerciseAudio(_, { newAudio, oldAudio }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: RENEW_EXERCISE_AUDIO_MUTATION,
          variables: { oldAudio, newAudio },
          fetchPolicy: "no-cache",
        });
      });
    },
    async updateAudio(_, { id, title }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: UPDATE_AUDIO_MUTATION,
          variables: { id, title },
          fetchPolicy: "no-cache",
        });
      });
    },
    async removeExerciseAudio(_, { id }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: REMOVE_EXERCISE_AUDIO_MUTATION,
          variables: { id },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default exercise;
