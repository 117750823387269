<template>
	<div>
		{{ $t("pages.accessDenied.title") }}
	</div>
</template>

<script>
export default {
  name: "AccessDenied",
};
</script>

<style lang="scss" scoped></style>
