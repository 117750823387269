import gql from "graphql-tag";

export const CHANGE_ORDER_EXERCISE_MUTATION = gql`
  mutation ChangeOrderExercise(
    $first: ChangeOrderExerciseInput!
    $second: ChangeOrderExerciseInput!
  ) {
    changeOrderExercise(first: $first, second: $second) {
      id
    }
  }
`;
