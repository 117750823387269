<template>
	<v-dialog
			v-model="recommendPWAInstallationDialog"
			max-width="600px"
	>
		<v-card>
			<v-card-title class="headline">
				{{ $t("serviceWorker.INSTALL_RECOMMENDATION.title") }}
			</v-card-title>
			<v-card-text>
				<p
						v-if="installationStep === 1"
						class="body-1"
						v-html="$t('serviceWorker.INSTALL_RECOMMENDATION.content')"
				/>
				<p
						v-else-if="installationStep === 2"
						class="body-1"
						v-html="$t('serviceWorker.INSTALL_RECOMMENDATION.iosSteps')"
				/>
			</v-card-text>

			<v-card-actions>
				<v-spacer />
				<v-btn
						v-if="installationStep !== 2"
						color="grey"
						text
						@click="disposeInstallPWADialog"
				>
					{{ $t("pages.common.cancel") }}
				</v-btn>
				<template v-if="isIOS">
					<v-btn
							v-if="installationStep === 1"
							color="green darken-1"
							text
							@click="() => (installationStep = 2)"
					>
						{{ $t("pages.common.next") }}
					</v-btn>
					<v-btn
							v-else-if="installationStep === 2"
							color="green darken-1"
							text
							@click="disposeInstallPWADialog"
					>
						{{ $t("pages.common.done") }}
					</v-btn>
				</template>
				<v-btn
						v-else
						color="green darken-1"
						text
						@click="installApp"
				>
					{{ $t("serviceWorker.INSTALL_RECOMMENDATION.install") }}
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import {
  getObjectFromLocalStorage,
  setToLocalStorage,
} from "@/utils/local-storage.util";

export default {
  name: "InstallPwaAppComponent",
  data() {
    return {
      installationStep: 1,
      isIOS: false,
      lastInstallPromptDate: getObjectFromLocalStorage("lastInstallPromptDate")
        ? new Date(getObjectFromLocalStorage("lastInstallPromptDate"))
        : null,
      recommendPWAInstallationDialog: false,
      deferredPrompt: null,
    };
  },
  watch: {
    lastInstallPromptDate(value) {
      if (!value) return;

      setToLocalStorage("lastInstallPromptDate", value.valueOf());
    },
  },
  created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      console.log("beforeinstallprompt", e);
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;

      if (
        this.lastInstallPromptDate &&
        this.$moment().diff(this.lastInstallPromptDate, "hours") < 24
      )
        return;

      setTimeout(() => {
        this.recommendPWAInstallationDialog = true;
      }, 5000);
    });

    window.addEventListener("appinstalled", () => {
      console.log("PWA was installed");
    });
  },
  mounted() {
    setTimeout(() => {
      this.checkPWAStatus();
    });
  },
  methods: {
    checkPWAStatus() {
      const userAgent = window.navigator.userAgent.toLowerCase();
      this.isIOS = /iphone|ipad|ipod/.test(userAgent);

      const isMacOS = userAgent.includes("macintosh"); // Check for macOS
      const isSafari =
        userAgent.includes("safari") &&
        !userAgent.includes("chrome") && // Exclude Chrome
        !userAgent.includes("android"); // Exclude Android

      const isFirefox = /firefox/i.test(userAgent);

      // Safari and Firefox doesn't support PWA installation
      if ((isSafari && isMacOS) || isFirefox) return;

      // if ("standalone" in navigator && navigator.standalone) {
      //   this.isPWAInstalled = true;
      // } else if (window.matchMedia("(display-mode: standalone)").matches) {
      //   this.isPWAInstalled = true;
      // } else {
      //   this.isPWAInstalled = false;
      // }
    },
    disposeInstallPWADialog() {
      this.installationStep = 1;
      this.lastInstallPromptDate = new Date();
      this.recommendPWAInstallationDialog = false;
    },
    installApp() {
      console.log("installApp");
      if (this.deferredPrompt) {
        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
            console.log("User accepted the install prompt");
          } else {
            console.log("User dismissed the install prompt");
          }
          this.lastInstallPromptDate = new Date();
          this.deferredPrompt = null;
        });
      }

      this.recommendPWAInstallationDialog = false;
    },
  },
};
</script>
