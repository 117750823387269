import Vue from "vue";
import VueRouter from "vue-router";
import PageNotFound from "@/views/PageNotFound";
import MainComponent from "@/components/MainComponent";
import AuthComponent from "@/components/AuthComponent";
import { getFromLocalStorage } from "@/utils/local-storage.util";
import { USER_ROLE } from "@/enums/user-role.enum";
import AccessDenied from "@/views/AccessDenied";
import LogoutView from "@/views/LogoutView";
import MainWithoutContainerRestrictionsComponent from "@/components/MainWithoutContainerRestrictionsComponent.vue";
import MainComponentWithFullContainer from "@/components/MainComponentWithFullContainer.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: MainWithoutContainerRestrictionsComponent,
    children: [
      {
        path: "",
        name: "main",
        component: () => import("../views/main/MainView.vue"),
      },
      {
        path: "",
        component: MainComponent,
        children: [
          {
            path: "about",
            name: "aboutCommunities",
            component: () => import("../views/main/pages/AboutView.vue"),
          },
          {
            path: "terms-of-service",
            name: "termsOfService",
            component: () =>
              import("../views/main/pages/TermsOfServiceView.vue"),
          },
          {
            path: "privacy-policy",
            name: "privacyPolicy",
            component: () =>
              import("../views/main/pages/PrivacyPolicyView.vue"),
          },
        ],
      },
      {
        path: "",
        component: MainComponentWithFullContainer,
        children: [
          {
            path: "whiteboard-demo",
            name: "whiteboard-demo",
            component: () => import("../views/main/BoardDemoView.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: MainComponent,
    children: [
      {
        path: "",
        component: () => import("../views/main/HomeView.vue"),
        children: [
          // {
          //   path: '',
          //   redirect: 'profile'
          // },
          {
            path: "search",
            name: "search",
            component: () => import("../views/main/SearchView.vue"),
            meta: {
              auth: true,
            },
          },
          {
            path: "profile/:userId(\\d+)",
            component: () => import("../layouts/ProfileLayout.vue"),
            children: [
              {
                path: "",
                name: "userProfile",
                component: () => import("../views/main/profile/MainView.vue"),
              },
              {
                path: "connections",

                component: () =>
                  import("../views/main/profile/ConnectionsView.vue"),
                meta: {
                  auth: true,
                },
              },
              {
                path: "schedule",
                name: "schedule",
                component: () =>
                  import("../views/main/profile/ScheduleView.vue"),
              },
              {
                path: "materials",
                component: () => import("../layouts/UserMaterialsLayout.vue"),
                children: [
                  {
                    path: "",
                    redirect: () => {
                      return { name: "lessons" };
                    },
                  },
                  {
                    path: "lessons/categories/:categoryId(\\d+)?",
                    name: "lessons",
                    component: () =>
                      import(
                        "../views/main/profile/materials/lesson/LessonsView.vue"
                      ),
                  },
                  {
                    path: "quizzes/categories/:categoryId(\\d+)?",
                    name: "quizzes",
                    component: () =>
                      import(
                        "../views/main/profile/materials/quiz/QuizzesView.vue"
                      ),
                  },
                ],
              },
            ],
          },
          // Self
          {
            path: "profile",
            // component: () => import("../layouts/EmptyLayout"),
            component: () => import("../layouts/ProfileLayout.vue"),
            meta: {
              auth: true,
            },
            children: [
              {
                path: "",
                name: "selfUserProfile",
                component: () =>
                  import("../views/main/my-profile/MainView.vue"),
              },
              {
                path: "preview",
                name: "selfUserProfilePreview",
                component: () =>
                  import("../views/main/my-profile/MainPreviewView.vue"),
              },
              {
                path: "schedule",
                name: "selfSchedule",
                component: () =>
                  import("../views/main/profile/ScheduleView.vue"),
              },
              {
                path: "notifications",
                name: "notifications",
                component: () =>
                  import("../views/main/my-profile/NotificationsView.vue"),
              },
              {
                path: "messages",
                component: () =>
                  import("../views/main/my-profile/MessagesView"),
                children: [
                  {
                    path: "",
                    name: "messages",
                    component: () =>
                      import("../components/messages/EmptyChatPage"),
                  },
                  {
                    path: ":id",
                    name: "messageChat",
                    component: () => import("../components/messages/ChatPage"),
                  },
                ],
              },
              {
                path: "connections",
                component: () => import("../layouts/EmptyLayout"),
                children: [
                  {
                    path: "shared-contacts",
                    name: "selfSharedContacts",
                    component: () =>
                      import(
                        "../views/main/my-profile/connection/SharedContactsView.vue"
                      ),
                  },
                  {
                    path: "",
                    name: "selfConnections",
                    component: () =>
                      import(
                        "../views/main/my-profile/connection/ConnectionsView.vue"
                      ),
                  },

                  {
                    path: "invitations",
                    name: "selfConnectionInvitations",
                    component: () =>
                      import(
                        "../views/main/my-profile/connection/ConnectionInvitationsView.vue"
                      ),
                  },
                ],
              },
              {
                path: "settings",
                component: () => import("../layouts/EmptyLayout"),
                children: [
                  {
                    path: "",
                    name: "profileSettings",
                    component: () =>
                      import(
                        "../views/main/my-profile/settings/ProfileSettingsView.vue"
                      ),
                  },
                  {
                    path: "main",
                    name: "profileSettingsMain",
                    component: () =>
                      import(
                        "../views/main/my-profile/settings/ProfileSettingsMainView.vue"
                      ),
                  },
                  {
                    path: "about",
                    name: "profileSettingsAbout",
                    component: () =>
                      import(
                        "../views/main/my-profile/settings/ProfileSettingsAboutView.vue"
                      ),
                  },
                  {
                    path: "interests",
                    name: "profileSettingsInterests",
                    component: () =>
                      import(
                        "../views/main/my-profile/settings/ProfileSettingsInterestsAndHobbiesView.vue"
                      ),
                  },
                  {
                    path: "textbooks",
                    name: "profileSettingsTextbooks",
                    component: () =>
                      import(
                        "../views/main/my-profile/settings/ProfileSettingsTextbooksView.vue"
                      ),
                  },
                  {
                    path: "prices",
                    name: "profileSettingsPrices",
                    component: () =>
                      import(
                        "../views/main/my-profile/settings/ProfileSettingsPricesView.vue"
                      ),
                  },
                  {
                    path: "detalization",
                    name: "profileSettingsDetalization",
                    component: () =>
                      import(
                        "../views/main/my-profile/settings/ProfileSettingsDetalizationView.vue"
                      ),
                  },
                ],
              },
              {
                path: "edit",
                component: () =>
                  import("../views/main/my-profile/EditProfileView.vue"),
              },
              {
                path: "materials",
                component: () => import("../layouts/UserMaterialsLayout.vue"),
                children: [
                  {
                    path: "",
                    name: "selfMaterials",
                    component: () =>
                      import(
                        "../views/main/my-profile/materials/SelfMaterials.vue"
                      ),
                  },
                  {
                    path: "lessons",
                    component: () =>
                      import(
                        "../views/main/my-profile/materials/lesson/LessonsView.vue"
                      ),
                    children: [
                      {
                        path: "categories/:categoryId(\\d+)?",
                        name: "selfLessons",
                        component: () =>
                          import(
                            "../views/main/my-profile/materials/lesson/OwnedLessonsView.vue"
                          ),
                      },
                      {
                        path: "saved-categories",
                        component: () => import("../layouts/EmptyLayout.vue"),
                        children: [
                          {
                            path: ":categoryId(\\d+)?",
                            name: "selfSavedLessons",
                            component: () =>
                              import(
                                "../views/main/my-profile/materials/lesson/ForkedLessonsView.vue"
                              ),
                          },
                          {
                            path: ":authorId(\\d+)/:startCategoryId(\\d+)-:categoryId(\\d+)",
                            name: "selfSavedPreviewLessons",
                            component: () =>
                              import(
                                "../views/main/my-profile/materials/lesson/ForkedLessonsView.vue"
                              ),
                          },
                        ],
                      },
                    ],
                  },

                  {
                    path: "quizzes",
                    component: () =>
                      import(
                        "../views/main/my-profile/materials/quiz/QuizzesView.vue"
                      ),
                    children: [
                      {
                        path: "categories/:categoryId(\\d+)?",
                        name: "selfQuizzes",
                        component: () =>
                          import(
                            "../views/main/my-profile/materials/quiz/OwnedQuizzesView.vue"
                          ),
                      },
                      {
                        path: "saved-categories",
                        component: () => import("../layouts/EmptyLayout.vue"),
                        children: [
                          {
                            path: ":categoryId(\\d+)?",
                            name: "selfSavedQuizzes",
                            component: () =>
                              import(
                                "../views/main/my-profile/materials/quiz/ForkedQuizzesView.vue"
                              ),
                          },
                          {
                            path: ":authorId(\\d+)/:startCategoryId(\\d+)-:categoryId(\\d+)",
                            name: "selfSavedPreviewQuizzes",
                            component: () =>
                              import(
                                "../views/main/my-profile/materials/quiz/ForkedQuizzesView.vue"
                              ),
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: "file-manager",
                    name: "fileManager",
                    component: () =>
                      import(
                        "../views/main/my-profile/materials/FileManager.vue"
                      ),
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: () => import("../layouts/EmptyLayout.vue"),
    children: [
      {
        path: "skills",
        component: () => import("../layouts/SkillLayout.vue"),
        children: [
          {
            name: "skill",
            path: ":skill",
            component: () => import("../views/SkillView.vue"),
          },
        ],
      },
      {
        path: "profile",
        component: () => import("../layouts/EmptyLayout.vue"),
        meta: {
          auth: true,
        },
        children: [
          {
            path: "live-lesson",
            component: () => import("../layouts/EmptyLayout.vue"),
            children: [
              {
                path: "",
                name: "liveLessons",
                component: () =>
                  import("../views/main/my-profile/live-lesson/LiveLesson.vue"),
              },
              {
                path: ":lessonScheduleId(\\d+)",
                name: "liveLesson",
                component: () =>
                  import(
                    "../views/main/my-profile/live-lesson/LessonSchedule.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "profile",
        component: () => import("../layouts/EmptyLayout.vue"),
        children: [
          {
            path: "materials",
            component: () => import("../layouts/EmptyLayout.vue"),
            children: [
              {
                path: "lessons/:lessonId(\\d+|new)",
                name: "lessonDetails",
                component: () =>
                  import(
                    "../views/main/profile/materials/lesson/CreateEditPreviewView.vue"
                  ),
              },
              {
                path: "quizzes/:quizId(\\d+|new)",
                name: "quizDetails",
                component: () =>
                  import(
                    "../views/main/profile/materials/quiz/CreateEditPreviewView.vue"
                  ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/",
    component: AuthComponent,
    children: [
      {
        path: "/login",
        name: "login",
        component: () => import("../views/LoginView.vue"),
      },
      {
        path: "/registration",
        component: () => import("../layouts/EmptyLayout.vue"),
        children: [
          {
            path: "",
            name: "registration-main",
            component: () => import("../views/RegistrationView.vue"),
          },
          {
            path: "teacher",
            name: "teacher-registration",
            component: () => import("../views/TeacherRegistrationView.vue"),
          },
          {
            path: "student",
            name: "student-registration",
            component: () => import("../views/StudentRegistrationView.vue"),
          },
        ],
      },
      {
        path: "/restore-user-password",
        name: "restore-user-password",
        component: () => import("../views/UserRestorePasswordView.vue"),
      },
      {
        path: "/confirm-restore-user-password",
        name: "confirm-restore-user-password",
        component: () => import("../views/UserConfirmRestorePasswordView.vue"),
      },
    ],
  },
  {
    path: "/logout",
    name: "logout",
    component: LogoutView,
  },
  {
    path: "/access-denied",
    component: AccessDenied,
  },
  {
    path: "*",
    component: PageNotFound,
  },
  {
    path: "/lesson-board/:lessonBoardId/preview",
    name: "lesson-board-preview",
    component: () => import("../views/BoardPreviewView.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

let previousRoute = null;
// check redirect role,permission
router.beforeEach(async (to, from, next) => {
  previousRoute = from;
  const isLoggedIn = getFromLocalStorage("accessToken");
  if (to.matched.some((m) => m.meta.auth) && !isLoggedIn) {
    return next({
      name: "login",
      query: {
        fromPage: to.path,
      },
    });
  }

  const tmpUser = getFromLocalStorage("user");
  if (isLoggedIn && tmpUser) {
    const user = JSON.parse(tmpUser);
    if (
      user.role !== USER_ROLE.ADMIN &&
      !to.matched
        .filter((m) => m.meta.permissions && m.meta.permissions.length)
        .every((m) => m.meta.permissions.includes(user.role))
    ) {
      return next({
        path: "/access-denied",
        query: {
          fromPage: from.path,
        },
      });
    }
  }

  // end check router prior with permission, role
  return next();
});

Vue.prototype.$previousRoute = () => previousRoute;
export default router;
