<template>
	<v-card>
		<v-card-title v-html="data.title" />
		<div v-html="data.description" />
		<v-card-actions>
			<v-spacer />
			<v-btn
					class="float-end"
					color="secondary"
					outlined
					type="submit"
					@click="close"
			>
				{{ $t("buttons.close") }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close", { id: this.data.id });
    },
  },
};
</script>
