<template>
	<v-main>
		<TopBarComponent
				:body-container-max-width="1200"
				:header-container-max-width="1200"
		>
			<slot />
		</TopBarComponent>
		<install-pwa-app-component />
	</v-main>
</template>

<script>
import TopBarComponent from "@/components/TopBarComponent";
import InstallPwaAppComponent from "@/components/InstallPWAAppComponent.vue";

export default {
  name: "MainLayout",
  components: { InstallPwaAppComponent, TopBarComponent },
};
</script>
