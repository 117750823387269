import { render, staticRenderFns } from "./StudentsSelectInputAvatar.vue?vue&type=template&id=33bf4476&scoped=true"
import script from "./StudentsSelectInputAvatar.vue?vue&type=script&lang=js"
export * from "./StudentsSelectInputAvatar.vue?vue&type=script&lang=js"
import style0 from "./StudentsSelectInputAvatar.vue?vue&type=style&index=0&id=33bf4476&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "33bf4476",
  null
  
)

export default component.exports