import gql from "graphql-tag";

export const CREATE_EXERCISE_AUDIO_MUTATION = gql`
  mutation ($exerciseId: Int!, $file: Upload!, $title: String!) {
    createExerciseAudio(
      data: { exerciseId: $exerciseId, file: $file, title: $title }
    ) {
      id
      fullUri
      title
    }
  }
`;
