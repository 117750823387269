<template>
	<main-layout-without-container-restrictions>
		<router-view />
	</main-layout-without-container-restrictions>
</template>

<script>
import MainLayoutWithoutContainerRestrictions from "@/layouts/MainLayoutWithoutContainerRestrictions.vue";

export default {
  components: { MainLayoutWithoutContainerRestrictions },
};
</script>
