import {
  deleteFromLocalStorage,
  getFromLocalStorage,
  setToLocalStorage,
} from "@/utils/local-storage.util";
import { LOGIN_MUTATION } from "@/graphql/mutations/login.mutation";
import { handleGqlResponseBody } from "@/utils/apollo.util";
import { LOGOUT_MUTATION } from "@/graphql/mutations/logout.mutation";
import { RESET_USER_PASSWORD_MUTATION } from "@/graphql/mutations/reset-user-password.mutation";
import { CHECK_RESET_USER_PASSWORD_TOKEN_MUTATION } from "@/graphql/mutations/check-reset-user-password-token.mutation";
import { CONFIRM_RESET_USER_PASSWORD_MUTATION } from "@/graphql/mutations/confirm-reset-user-password.mutation";

export const clearLoginData = () => {
  deleteFromLocalStorage("accessToken");
  deleteFromLocalStorage("refreshToken");
  deleteFromLocalStorage("user");
};

const authentication = {
  state: {
    accessToken: getFromLocalStorage("accessToken"),
    refreshToken: getFromLocalStorage("refreshToken"),
  },
  getters: {
    getAccessToken(state) {
      return state.accessToken;
    },
  },
  mutations: {
    setAccessAndRefreshTokens(state, { accessToken, refreshToken }) {
      state.accessToken = setToLocalStorage("accessToken", accessToken);
      state.refreshToken = setToLocalStorage("refreshToken", refreshToken);
    },
    logOut() {
      this.commit("clearCurrentUser");
      clearLoginData();
    },
  },
  actions: {
    async loginUser({ commit }, credentials) {
      const result = await handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: LOGIN_MUTATION,
          variables: credentials,
        });
      });

      commit("setCurrentUser", result.user);
      commit("setAccessAndRefreshTokens", {
        accessToken: result.accessToken,
        refreshToken: result.refreshToken,
      });
    },
    async logoutUser({ commit, state }) {
      await handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: LOGOUT_MUTATION,
          variables: {
            refreshToken: state.refreshToken ?? "",
          },
        });
      });

      commit("logOut");
    },
    resetUserPassword(_, { email }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: RESET_USER_PASSWORD_MUTATION,
          variables: { email },
        });
      });
    },
    checkResetUserPasswordToken(_, { token, code }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: CHECK_RESET_USER_PASSWORD_TOKEN_MUTATION,
          variables: { token, code },
        });
      });
    },
    confirmResetUserPassword(_, { token, code, password }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: CONFIRM_RESET_USER_PASSWORD_MUTATION,
          variables: { token, code, password },
        });
      });
    },
  },
};

export default authentication;
