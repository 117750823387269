import {
  getFromLocalStorage,
  setToLocalStorage,
} from "@/utils/local-storage.util";
import { handleGqlResponseBody } from "@/utils/apollo.util";
import { GET_CURRENT_USER_QUERY } from "@/graphql/queries/user/get-current-user.query";
import { GET_CURRENT_USER_BALANCE_QUERY } from "@/graphql/queries/user/get-current-user-balance.query";
import { GET_USER_BALANCE_TRANSACTIONS_PAGINATION_QUERY } from "@/graphql/queries/user/get-user-balance-transactions-pagination.query";
import { toSnakeCase } from "@/utils/string";

const currentUser = {
  state: {
    user: getFromLocalStorage("user")
      ? JSON.parse(getFromLocalStorage("user"))
      : null,
    balance: null,
  },
  getters: {
    getCurrentUser(state) {
      return state.user;
    },
    getCurrentUserBalance(state) {
      return state.balance;
    },
  },
  mutations: {
    setCurrentUser(state, user) {
      setToLocalStorage("user", JSON.stringify(user));
      state.user = user;
    },
    setCurrentUserBalance(state, balance) {
      state.balance = balance;
    },
    clearCurrentUser(state) {
      state.user = null;
    },
  },
  actions: {
    async updateCurrentUser({ commit }) {
      const result = await handleGqlResponseBody((apollo) => {
        return apollo.mutate({
          mutation: GET_CURRENT_USER_QUERY,
        });
      });

      commit("setCurrentUser", result);
      return result;
    },
    async updateCurrentUserBalance({ commit }) {
      const result = await handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_CURRENT_USER_BALANCE_QUERY,
        });
      });

      commit("setCurrentUserBalance", result.balance);
    },
    async getCurrentUserBalanceTransactions(
      _,
      { skip, take, orderBy, fromDate, toDate, type }
    ) {
      orderBy = orderBy.map((o) => ({
        ...o,
        fieldName: toSnakeCase(o.fieldName),
      }));

      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_USER_BALANCE_TRANSACTIONS_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            fromDate,
            toDate,
            type,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default currentUser;
