<template>
	<v-main
			id="landing-view"
			class="landing-view"
	>
		<slot />
	</v-main>
</template>

<script>
export default {
  name: "MainLayoutWithoutContainerRestrictions",
};
</script>
