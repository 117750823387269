import gql from "graphql-tag";

export const GET_USER_CONTACT_SHARINGS_PAGINATION_QUERY = gql`
  query (
    $query: String
    $notMarkedAsRead: Boolean
    $skip: Int!
    $take: Int!
    $orderBy: [OrderByInput!]
  ) {
    getUserContactSharings(
      data: {
        query: $query
        notMarkedAsRead: $notMarkedAsRead
        pagination: { skip: $skip, take: $take }
        orderBy: $orderBy
      }
    ) {
      total
      hasMore
      items {
        id
        telNumber
        email
        message
        createdAt
        readAt
      }
    }
  }
`;
