import gql from "graphql-tag";

export const CHECK_EXERCISE_PREVIEW_MUTATION = gql`
  mutation CheckExercisePreview(
    $data: CheckExerciseDataTypeInput!
    $answer: CheckExerciseAnswerTypeInput!
    $points: Float!
    $quizId: Int
  ) {
    checkExercisePreview(
      data: { data: $data, answer: $answer, points: $points, quizId: $quizId }
    ) {
      dragTheWords {
        incorrect
        points
        showIncorrect
        showError
      }
      fillInTheBlanks {
        incorrect
        points
        showIncorrect
        showError
      }
      findSynonym {
        incorrect
        points
        showIncorrect
        showError
      }
      findThePlace {
        incorrect
        points
        showIncorrect
        showError
      }
      markTheWords {
        incorrect
        points
        showIncorrect
        showError
      }
      multipleChoice {
        incorrect
        points
        showIncorrect
        showError
      }
      singleChoice {
        incorrect
        points
        showIncorrect
        showError
      }
      trueFalse {
        incorrect
        points
        showIncorrect
        showError
      }
    }
  }
`;
