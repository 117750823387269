import gql from "graphql-tag";

export const CREATE_EXERCISE_MUTATION = gql`
  mutation CreateExercise(
    $title: String!
    $description: String
    $errorHint: String
    $quizId: Int!
    $order: Int!
    $points: Float
    $isVocabulary: Boolean
    $audioData: CreateAudioInput
    $audioHidden: Boolean
    $audioAutoplay: Boolean
    $dragTheWords: UpsertDragTheWordsExerciseInput
    $fillInTheBlanks: UpsertFillInTheBlanksExerciseInput
    $findSynonym: UpsertFindSynonymExerciseInput
    $findThePlace: UpsertFindThePlaceExerciseInput
    $markTheWords: UpsertMarkTheWordsExerciseInput
    $multipleChoice: UpsertMultipleChoiceExerciseInput
    $singleChoice: UpsertSingleChoiceExerciseInput
    $trueFalse: UpsertTrueFalseExerciseDataInput
  ) {
    createExercise(
      data: {
        title: $title
        description: $description
        errorHint: $errorHint
        quizId: $quizId
        order: $order
        points: $points
        isVocabulary: $isVocabulary
        audioData: $audioData
        audioAutoplay: $audioAutoplay
        audioHidden: $audioHidden
        dragTheWords: $dragTheWords
        fillInTheBlanks: $fillInTheBlanks
        findSynonym: $findSynonym
        findThePlace: $findThePlace
        markTheWords: $markTheWords
        multipleChoice: $multipleChoice
        singleChoice: $singleChoice
        trueFalse: $trueFalse
      }
    ) {
      id
      title
      description
      createdAt
      errorHint
      isVocabulary
      order
      points
      type
      updatedAt
      version
      audio {
        id
        fullUri
        title
      }
      audioAutoplay
      audioHidden
      quizId
      dragTheWords {
        answer
        data
        audios
        images
        text
      }
      fillInTheBlanks {
        answer
        audios
        images
        text
      }
      findSynonym {
        answer
        text
      }
      findThePlace {
        answer
        data
        text
      }
      markTheWords {
        answer
        text
      }
      multipleChoice {
        answer
        data
      }
      singleChoice {
        answer
        data
      }
      trueFalse {
        answer
      }
    }
  }
`;
