<template>
	<main-layout-with-full-container>
		<router-view />
	</main-layout-with-full-container>
</template>

<script>
import MainLayoutWithFullContainer from "@/layouts/MainLayoutWithFullContainer.vue";

export default {
  components: { MainLayoutWithFullContainer },
};
</script>
