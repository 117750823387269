<template>
	<v-app>
		<v-toolbar
				id="top-toolbar"
				class="toolbar"
				elevation="2"
				height="52"
		>
			<div
					v-if="searchBoxFocused && $vuetify.breakpoint.smAndDown"
					class="mobile-search-box-wrapper"
			/>
			<div
					:style="
						headerContainerMaxWidth
							? `max-width: ${headerContainerMaxWidth}px;`
							: ''
					"
					class="row-container"
			>
				<router-link
						class="text-decoration-none"
						to="/"
				>
					<logo-component
							:height="40"
							:width="110"
							class="d-block"
							color="#E98700"
					/>
				</router-link>

				<v-toolbar-items
						v-if="currentUser && !$route.path.startsWith('/search')"
						class="pl-4 fill-width"
				>
					<v-autocomplete
							ref="searchBox"
							v-model="selectedUser"
							:height="40"
							:item-text="itemText"
							:items="paginationContext.items"
							:label="$t('pages.common.searchAnyPerson')"
							:loading="getLoadingByKey(SEARCH_USERS_KEY)"
							:multiple="false"
							:placeholder="$t('pages.common.typeAnyPersonsName')"
							:search-input.sync="query"
							:style="computedSearchBoxStyles"
							append-icon=""
							attach=".search-box"
							class="search-box mr-auto"
							dense
							hide-details
							item-value="id"
							outlined
							prepend-inner-icon="mdi-magnify"
							return-object
							rounded
							@blur="
								() => {
									searchBoxFocused = false;
									$refs.searchBox.blur();
								}
							"
							@focus="searchBoxFocused = true"
					>
						<template #progress>
							<v-progress-linear
									absolute
									height="3"
									indeterminate
									rounded
							/>
						</template>
						<template #no-data>
							<div class="px-2">
								<template v-if="!query || query.length < 3">
									{{ $t("pages.common.searchForUsers") }}
								</template>
								<template v-else>
									{{ $t("pages.common.noMatchingUsers") }}
								</template>
							</div>
						</template>
						<template
								v-if="query && query.length >= 3"
								#append-item
						>
							<div class="px-2 text-center">
								<router-link
										:to="{ name: 'search', query: { query } }"
										class="d-block"
								>
									{{ $t("pages.common.seeAllResults") }}
								</router-link>
							</div>
						</template>
						<template #item="data">
							<StudentsSelectInputAvatar
									:first-name="data.item.firstName"
									:grouped="false"
									:last-name="data.item.lastName"
									:profile-image="data.item.profilePhotoX64"
									:size="32"
							/>
							<v-list-item-content>
								<v-list-item-title>
									{{ data.item.firstName }}
									{{ data.item.lastName }}
								</v-list-item-title>
							</v-list-item-content>
						</template>
					</v-autocomplete>
				</v-toolbar-items>
				<v-spacer v-if="$vuetify.breakpoint.mdAndUp || !currentUser" />
				<v-toolbar-items class="ml-4 align-center">
					<template v-if="currentUser">
						<v-badge
								:content="notificationsCount"
								:value="notificationsCount > 0"
								bordered
								color="#e98700"
								overlap
						>
							<v-btn
									color="#e98700"
									fab
									outlined
									to="/profile/notifications"
									x-small
							>
								<v-icon>mdi-bell-outline</v-icon>
							</v-btn>
						</v-badge>
						<v-badge
								v-if="$vuetify.breakpoint.mdAndUp"
								:content="messagesCount"
								:value="messagesCount > 0"
								bordered
								color="secondary"
								overlap
						>
							<v-btn
									class="ml-3"
									color="secondary"
									fab
									outlined
									to="/profile/messages"
									x-small
							>
								<v-icon>mdi-email-outline</v-icon>
							</v-btn>
						</v-badge>
						<router-link
								:to="{ name: 'profileSettings' }"
								class="d-flex black--text text-decoration-none align-center ml-2"
						>
							<AvatarComponent
									:first-name="currentUser.firstName"
									:last-name="currentUser.lastName"
									:profile-image="currentUser.profilePhotoX64"
									:size="32"
									class="ml-2"
							/>

							<div
									v-if="$vuetify.breakpoint.smAndUp"
									class="ml-2 no-wrap-text"
							>
								{{ currentUser.firstName }}
							</div>
						</router-link>
					</template>
					<template v-else>
						<v-row
								class="fill-height"
								no-gutters
						>
							<v-col align-self="center">
								<v-btn
										:to="{ name: 'login' }"
										color="indigo"
										outlined
								>
									{{ $t("pages.common.login") }}
								</v-btn>
							</v-col>
						</v-row>
					</template>
				</v-toolbar-items>
			</div>
		</v-toolbar>
		<div
				:style="{
					...(bodyContainerMaxWidth
						? { 'max-width': `${bodyContainerMaxWidth}px` }
						: {}),
				}"
				class="fill-width fill-height mx-auto"
		>
			<div
					:class="{
						'fill-height':
							$route.name === 'messages' ||
							$route.name === 'messageChat' ||
							$route.name === 'fileManager',
					}"
					:style="{
						...($vuetify.breakpoint.smAndDown
							? { 'padding-bottom': `62px` }
							: {}),
					}"
					class="fill-width"
					style="min-height: 100%; box-sizing: border-box; padding-top: 52px"
			>
				<slot />
			</div>
		</div>
		<div
				v-if="$vuetify.breakpoint.smAndDown && currentUser"
				id="bottom-toolbar"
				class="tabs-container"
		>
			<div class="tabs-wrapper">
				<router-link
						:to="{ name: 'selfUserProfile' }"
						active-class="active"
						class="tab-item"
						exact
				>
					<div class="tab-item__icon">
						<v-icon>mdi-home</v-icon>
					</div>
					<div class="tab-item__text">
						{{ $t("pages.common.tabs.main") }}
					</div>
				</router-link>
				<router-link
						:to="{ name: 'selfConnections' }"
						active-class="active"
						class="tab-item"
				>
					<div class="tab-item__icon">
						<v-badge
								:content="connectionsCount"
								:value="connectionsCount > 0"
								bordered
								offset-x="10"
								offset-y="10"
						>
							<v-icon>mdi-account-multiple</v-icon>
						</v-badge>
					</div>
					<div class="tab-item__text">
						{{ $t("pages.common.tabs.myNetwork") }}
					</div>
				</router-link>
				<router-link
						:to="{ name: 'selfSchedule' }"
						active-class="active"
						class="tab-item"
				>
					<div class="tab-item__icon">
						<v-icon>mdi-calendar-clock</v-icon>
					</div>
					<div class="tab-item__text">
						{{ $t("pages.common.tabs.schedule") }}
					</div>
				</router-link>
				<router-link
						:to="{ name: 'selfMaterials' }"
						active-class="active"
						class="tab-item"
				>
					<div class="tab-item__icon">
						<v-icon>mdi-book-education</v-icon>
					</div>
					<div class="tab-item__text">
						{{ $t("pages.common.tabs.materials") }}
					</div>
				</router-link>
				<router-link
						:to="{ name: 'messages' }"
						active-class="active"
						class="tab-item"
				>
					<div class="tab-item__icon">
						<v-badge
								:content="messagesCount"
								:value="messagesCount > 0"
								bordered
								offset-x="10"
								offset-y="10"
						>
							<v-icon>mdi-email-outline</v-icon>
						</v-badge>
					</div>
					<div class="tab-item__text">
						{{ $t("pages.common.tabs.messages") }}
					</div>
				</router-link>
			</div>
		</div>

		<urgent-notification-wrapper />
		<div
				v-if="searchBoxFocused"
				class="custom-overlay"
		/>
	</v-app>
</template>

<script>
import { mapGetters } from "vuex";
import LogoComponent from "@/components/LogoComponent";
import StudentsSelectInputAvatar from "@/components/profile/schedule/plan-new-lesson/StudentsSelectInputAvatar.vue";
import AvatarComponent from "@/components/profile/AvatarComponent.vue";
import UrgentNotificationWrapper from "@/components/profile/notification/urgent/UrgentNotificationWrapper.vue";
import { paginationByKeyLaterMixin } from "@/mixins/pagination-by-key-later.mixin";

const SEARCH_USERS_KEY = "searchUsers";
const SEARCH_BOX_WIDTH = 300;
const UPDATE_NOTIFICATION_BADGES_INTERVAL = 20000;
export default {
  components: {
    UrgentNotificationWrapper,
    AvatarComponent,
    StudentsSelectInputAvatar,
    LogoComponent,
  },
  mixins: [paginationByKeyLaterMixin],
  props: {
    headerContainerMaxWidth: { type: Number, required: false, default: null },
    bodyContainerMaxWidth: { type: Number, required: false, default: null },
  },
  data() {
    return {
      buildVersion: process.env.VUE_APP_BUILD_VERSION,
      notificationsUpdateInterval: null,
      searchBoxFocused: false,
      query: "",
      selectedUser: null,
      showNoData: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
      notificationComputedBadges: "getNotificationComputedBadges",
      messagesCount: "getMessagesCount",
      connectionsCount: "getConnectionsCount",
    }),
    SEARCH_USERS_KEY: () => SEARCH_USERS_KEY,
    paginationContext() {
      return this.getPaginationContext(SEARCH_USERS_KEY);
    },
    notificationsCount() {
      if (!this.notificationComputedBadges) return null;
      return this.notificationComputedBadges[null];
    },
    computedSearchBoxStyles() {
      const defaultStyles = {
        "z-index": 99,
      };
      if (this.$vuetify.breakpoint.smAndDown) {
        if (this.searchBoxFocused) {
          return {
            ...defaultStyles,
            width: `100%`,
            position: "absolute",
            left: 0,
            "padding-left": "10px",
            "padding-right": "10px",
          };
        }

        return {
          ...defaultStyles,
          width: `100%`,
        };
      } else {
        if (this.searchBoxFocused) {
          let expandedWidth =
            this.$refs.searchBox.$el.parentElement.offsetWidth;
          if (expandedWidth > 460) expandedWidth = 460;

          return {
            ...defaultStyles,
            "max-width": `${expandedWidth}px`,
          };
        } else
          return { ...defaultStyles, "max-width": `${SEARCH_BOX_WIDTH}px` };
      }
    },
  },
  watch: {
    query(val) {
      if (!val || val.length < 3) {
        this.paginationContext.clearPage();
        return;
      }
      this.paginationContext.onFilterChange("query", val);
    },
    async selectedUser(user) {
      if (!user) return;
      await this.$router.push({
        name: "userProfile",
        params: { userId: user.id },
      });
      // Clear
      this.query = "";
      this.selectedUser = null;
      this.$refs.searchBox.blur();
    },
    async notificationsCount(current, old) {
      if (old === null) return;
      if (current <= old) return;

      await this.$store.dispatch("playNewNotificationSound");
    },
    async messagesCount(current, old) {
      if (old === null) return;
      if (current <= old) return;

      await this.$store.dispatch("playNewMessageSound");
    },
    async connectionsCount(current, old) {
      if (old === null) return;
      if (current <= old) return;

      await this.$store.dispatch("playNewConnectionSound");
    },
  },
  created() {
    this.initPaginationByKey(
      SEARCH_USERS_KEY,
      "getSearchUsersQuery",
      {
        query: null,
      },
      ["query"],
      {
        appendToItems: true,
        itemsPerPage: 20,
      }
    );
  },
  async mounted() {
    if (this.currentUser) {
      await this.$store.dispatch("updateCurrentUser");
      await this.$store.dispatch("updateNotificationCountQuery");

      this.notificationsUpdateInterval = setInterval(async () => {
        await this.$store.dispatch("updateNotificationCountQuery");
      }, UPDATE_NOTIFICATION_BADGES_INTERVAL);
    }
  },
  beforeDestroy() {
    if (this.notificationsUpdateInterval) {
      clearInterval(this.notificationsUpdateInterval);
    }
  },
  methods: {
    itemText(data) {
      return `${data.firstName} ${data.lastName}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.row-container {
  width: 100%;
  display: inherit;
  margin: auto;
}

.toolbar {
  position: fixed;
  width: 100%;
  z-index: 201;

  .mobile-search-box-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background: #fff;
    z-index: 1;
  }

  .v-toolbar__title {
    color: #e98700;
  }
}
</style>

<style lang="scss">
.toolbar {
  .search-box {
    .v-input__slot {
      padding-left: 10px;
    }

    .v-menu__content {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
}

.main-tabs {
  position: fixed;
  bottom: 0;
  display: block;
  overflow: hidden;
  z-index: 999;

  &.v-tabs--grow > .v-tabs-bar .v-tab {
    max-width: 100px;
  }

  .v-tabs-slider-wrapper {
    top: 0;
  }

  .v-slide-group__prev {
    display: none;
  }

  .v-tab {
    text-transform: none;
    padding: 0 5px;
    min-width: 40px;
    font-size: 11px;
    color: #5e5c5c !important;

    > *:first-child {
      margin-bottom: 4px !important;
    }

    &.active-tab {
      color: #000 !important;
    }
  }
}

.custom-overlay {
  background: rgba(0, 0, 0, 0.49);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 200;
}
</style>
