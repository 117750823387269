import {
  ALPHA_NUMERIC_CHARACTERS,
  NUMERIC_CHARACTERS,
} from "@/utils/constants.util";
import { stringHash } from "@/utils/string.util";

export const numberArrayGenerator = (max = 0, withoutZero = true) => {
  const array = Array.from(Array(max + 1).keys());
  return withoutZero ? array.slice(1) : array;
};

export function baseGenerator(chars, length) {
  let result = "";
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)];
  }
  return result;
}

export function chatRandomTmpIdGenerator(length = 32) {
  return baseGenerator(ALPHA_NUMERIC_CHARACTERS, length);
}

export function intercomActionCodeGenerator(length = 8) {
  return baseGenerator(NUMERIC_CHARACTERS, length);
}

const AVATAR_COLORS = [
  "#1ABC9C", // Turquoise
  "#16A085", // Green Sea
  "#2ECC71", // Emerald
  "#27AE60", // Nephritis
  "#3498DB", // Peter River
  "#2980B9", // Belize Hole
  "#9B59B6", // Amethyst
  "#8E44AD", // Wisteria
  "#34495E", // Wet Asphalt
  "#2C3E50", // Midnight Blue
  "#F1C40F", // Sun Flower
  "#F39C12", // Orange
  "#E67E22", // Carrot
  "#D35400", // Pumpkin
  "#E74C3C", // Alizarin
  "#C0392B", // Pomegranate
  "#BDC3C7", // Silver
  "#95A5A6", // Concrete
  "#7F8C8D", // Asbestos
  "#FF5733", // Radience Orange
  "#C70039", // Radical Red
  "#900C3F", // Dark Red
  "#581845", // Dark Purple
  "#6C3483", // Studio Purple
  "#1F618D", // Royal Blue
  "#117864", // Forest Green
  "#B7950B", // Gold
  "#A04000", // Burnt Orange
  "#873600", // Chocolate
  "#641E16", // Dark Brown
  "#A569BD", // Light Purple
  "#7D3C98", // Medium Purple
  "#148F77", // Dark Sea Green
  "#117A65", // Sea Green
  "#229954", // Spring Green
  "#45B39D", // Light Sea Green
  "#5DADE2", // Sky Blue
  "#3498DB", // Medium Blue
  "#717D7E", // Dark Silver
  "#424949", // Charcoal
  "#2980B9", // Deep Blue
  "#D35400", // Pumpkin
  "#1ABC9C", // Light Turquoise
  "#117864", // Forest Green
  "#2980B9", // Belize Hole
  "#D4AC0D", // Yellow
  "#7D3C98", // Medium Purple
  "#45B39D", // Light Sea Green
  "#34495E", // Wet Asphalt
  "#2C3E50", // Midnight Blue
];

export function randomAvatarColor() {
  return AVATAR_COLORS[Math.floor(Math.random() * AVATAR_COLORS.length)];
}

export function getAvatarColor(fullName) {
  return AVATAR_COLORS[Math.abs(stringHash(fullName)) % AVATAR_COLORS.length];
}

const REVIEW_QUESTION_COLORS = [
  "#FF6F61", // Coral
  "#6B5B95", // Purple
  "#88B04B", // Olive Green
  "#92A8D1", // Light Blue
  "#F9A65A", // Orange
  "#FFCC5C", // Yellow
  "#F3B4A2", // Peach
  "#C1C6C8", // Light Grey
  "#D5AAFF", // Lavender
  "#FFABAB", // Soft Pink
  "#FFC3A0", // Pastel Peach
  "#D4E157", // Lime Green
  "#B39DDB", // Light Purple
  "#FF8A65", // Salmon
  "#CE93D8", // Light Lilac
  "#F57C00", // Deep Orange
  "#C62828", // Red
  "#0288D1", // Blue
  "#00897B", // Teal
  "#D32F2F", // Red
  "#7B1FA2", // Purple
  "#C2185B", // Pink
  "#F57F17", // Amber
  "#5C6BC0", // Blue Grey
  "#FBC02D", // Yellow
  "#00796B", // Teal
  "#43A047", // Green
  "#5C6F7A", // Slate
  "#D32F2F", // Deep Red
  "#C2185B", // Pink
  "#1E88E5", // Blue
  "#7B1FA2", // Purple
  "#7B1FA2", // Purple
  "#B71C1C", // Dark Red
  "#F06292", // Pink
  "#7B1FA2", // Purple
  "#0288D1", // Blue
  "#388E3C", // Green
  "#F9A825", // Golden
  "#F57F17", // Amber
  "#8E24AA", // Dark Purple
  "#D81B60", // Pink
  "#8E24AA", // Dark Purple
  "#F57F17", // Amber
  "#FF6F61", // Coral
  "#FF8A65", // Salmon
  "#FFB74D", // Orange
];

export function getReviewQuestionColor(id) {
  return REVIEW_QUESTION_COLORS[id % REVIEW_QUESTION_COLORS.length];
}
