<template>
	<img
			v-if="profileImage?.fullUri"
			:height="size"
			:src="profileImage.fullUri"
			:width="size"
			alt="Avatar"
	>
	<span
			v-else
			:style="`font-size: ${size * 0.53}px`"
			class="white--text"
			v-text="`${firstName.charAt(0)}${lastName.charAt(0)}`"
	/>
</template>

<script>
export default {
  props: {
    size: { type: Number, required: false, default: null },
    profileImage: { type: Object, required: false, default: null },
    firstName: { type: String, required: false, default: null },
    lastName: { type: String, required: false, default: null },
  },
};
</script>
