export const USER_NOTIFICATION_TYPES = {
  Lesson: "Lesson",
  Other: "Other",
};

export const USER_NOTIFICATION_ACTIONS = {
  Lesson: {
    LessonScheduled: "LessonScheduled",
  },
  Other: {
    Welcome: "Welcome",
  },
};
