import gql from "graphql-tag";

export const GET_USER_CONNECTIONS_HISTORY_PAGINATION_QUERY = gql`
  query (
    $type: UserConnectionType!
    $status: UserConnectionStatus
    $skip: Int!
    $take: Int!
    $orderBy: [OrderByInput!]
  ) {
    getUserConnectionsHistory(
      data: {
        type: $type
        status: $status
        pagination: { skip: $skip, take: $take }
        orderBy: $orderBy
      }
    ) {
      total
      hasMore
      items {
        id
        status
        message
        connectionStatus
        user {
          id
          firstName
          lastName
          shortAbout
          profilePhotoX128 {
            fullUri
          }
        }
        createdAt
      }
    }
  }
`;
