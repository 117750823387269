export const loggerMixin = (topic) => ({
  data() {
    return {
      logger: new Logger(topic),
    };
  },
});

export class Logger {
  constructor(topic) {
    this.topic = topic;
  }

  // eslint-disable-next-line no-unused-vars
  debug(section, ...args) {
    // console.log(`[DEBUG] [${this.topic}] [${section}]`, ...args);
  }

  // eslint-disable-next-line no-unused-vars
  warn(section, ...args) {
    // console.log(`[WARN] [${this.topic}] [${section}]`, ...args);
  }
}
