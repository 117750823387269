import gql from "graphql-tag";

export const GET_QUIZ_HISTORY_QUERY = gql`
  query ($quizHistoryId: Int!) {
    getQuizHistory(data: { quizHistoryId: $quizHistoryId }) {
      id
      maxPoints
      earnedPoints
      createdAt
      user {
        id
        firstName
        lastName
        profilePhotoX64 {
          fullUri
        }
        email
      }
      nonRegisteredUser {
        id
        fullName
        telNumber
      }
      quiz {
        id
        title
        description
        showCorrectnessOnError
        itemsPerPage
        deletedAt
      }
    }
  }
`;
