import Vue from "vue";
import VueI18n from "vue-i18n";
import { getObjectFromLocalStorage } from "@/utils/local-storage.util";

Vue.use(VueI18n);

const localeMap = {
  "en-US": "en",
  "ru-RU": "ru",
};

function getFallbackLanguage() {
  const languages = navigator.languages || [navigator.language];
  const existingLanguage = languages.find((lang) => lang in localeMap);
  return existingLanguage ? localeMap[existingLanguage] : "en";
}

const defaultLocale =
  getObjectFromLocalStorage("lang") || getFallbackLanguage();

export const i18n = new VueI18n({
  locale: defaultLocale, // set locale
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: {
    en: require("../i18n/en"),
    ru: require("../i18n/ru"),
  },
});
