const microphone = {
  state: {
    isLoading: false,
    device: {
      selected: null,
      items: [],
    },
    stream: null,
    audioAvailability: true,
  },
  getters: {
    getAudioStream(state) {
      return state.stream;
    },
    getAudioDevices(state) {
      return state.device;
    },
    getMicrophoneAudioAvailability(state) {
      return state.audioAvailability;
    },
  },
  actions: {
    resetAudioDevices({ state }) {
      state.device.selected = state.device.items[0].id;
    },
    async reloadAudioDevices({ state }) {
      state.device.items = [];

      const deviceInfos = await navigator.mediaDevices.enumerateDevices();
      for (const deviceInfo of deviceInfos) {
        const device = {
          id: deviceInfo.deviceId,
          label: deviceInfo.label,
        };

        switch (deviceInfo.kind) {
          case "audioinput":
            state.device.items.push(device);
            break;
        }
      }
    },
    async reloadAudioStream({ state }) {
      if (!state.audioAvailability) {
        state.stream = new MediaStream();
        return;
      }

      const constraints = {
        audio: state.audioAvailability
          ? {
              deviceId: state.device.selected.input
                ? { exact: state.device.selected.input }
                : undefined,
            }
          : false,
        video: false,
      };

      state.stream = await navigator.mediaDevices.getUserMedia(constraints);
    },
    async initializeAudioStream({ state, dispatch }) {
      state.isLoading = true;
      try {
        await dispatch("reloadAudioDevices");
        await dispatch("resetAudioDevices");
        await dispatch("reloadAudioStream");
      } catch (e) {
        state.isLoading = false;
        throw e;
      }
    },
    async destroyAudioStream({ state }) {
      for (const track of state.stream.getTracks()) {
        track.stop();
      }

      state.stream = null;
    },
    async setAudioInputAvailability({ state, dispatch }, value) {
      state.audioAvailability = value;
      await dispatch("reloadAudioStream");
    },
  },
};

export default microphone;
