import gql from "graphql-tag";

export const GET_QUIZZES_QUERY = gql`
  query (
    $type: QuizType!
    $skip: Int!
    $take: Int!
    $search: String
    $orderBy: [OrderByInput!]
    $privacy: LessonQuizPrivacy
    $isVocabulary: Boolean
    $exceptLinked: Boolean
    $lessonId: Int
    $userId: Int!
    $categoryId: Int
  ) {
    getQuizzes(
      data: {
        categoryId: $categoryId
        orderBy: $orderBy
        pagination: { skip: $skip, take: $take }
        type: $type
        search: $search
        privacy: $privacy
        isVocabulary: $isVocabulary
        exceptLinked: $exceptLinked
        lessonId: $lessonId
        userId: $userId
      }
    ) {
      hasMore
      total
      items {
        id
        title
        coverUri
        createdAt
        isVocabulary
        privacy
        updatedAt
        author {
          id
          firstName
          lastName
          profilePhotoX64 {
            fullUri
          }
        }
        authorId
        fork {
          id
          categoryId
          quizId
          userId
          createdAt
        }
      }
    }
  }
`;
