import {
  getFromLocalStorage,
  setToLocalStorage,
} from "@/utils/local-storage.util";

const STORE_KEY = "lessonBoardStore";
const lessonBoard = {
  state: {
    memory: getFromLocalStorage(STORE_KEY)
      ? JSON.parse(getFromLocalStorage("lessonBoardStore"))
      : {
          tool: {},
        },
  },
  getters: {},
  mutations: {
    async saveMemory(state) {
      setToLocalStorage(STORE_KEY, JSON.stringify(state.memory));
    },
  },
  actions: {
    async lessonBoardSaveToToolMemory({ commit, state }, { key, value }) {
      state.memory.tool[key] = value;
      commit("saveMemory");
    },
    async lessonBoardGetFromToolMemory({ state }, { key }) {
      return state.memory.tool[key];
    },
    async lessonBoardSaveToolPropertyToMemory(
      { commit, state },
      { toolKey, propertyKey, propertyValue }
    ) {
      state.memory.tool[toolKey][propertyKey] = propertyValue;
      commit("saveMemory");
    },
  },
};

export default lessonBoard;
