import { gqlErrorToast } from "@/utils/graphql-errors";

export default {
  install(Vue, { toast, i18n }) {
    /**
     *
     * @param {function(): Promise<void>} promise
     * @param {{[errorCode: string]: (error: any) => any }} except
     * @param errorCb: () => void
     * @param notShowToast
     * @param isExceptPartial
     */
    Vue.prototype.$showGqlError = async function (
      promise,
      errorCb = () => {},
      except = {},
      notShowToast = false,
      isExceptPartial = false
    ) {
      try {
        return await (typeof promise === "function" ? promise() : promise);
      } catch (e) {
        const exceptMessages = Object.keys(except);
        const { excluded, handledErrors, hasError } = gqlErrorToast(
          toast,
          i18n,
          e,
          exceptMessages,
          notShowToast,
          isExceptPartial
        );

        // console.log("GGGGG", handledErrors);
        for (const key of Object.keys(excluded)) {
          except[key]?.(excluded[key]);
        }
        // throw e
        errorCb(e, { handledErrors, hasError });
      }
    };
  },
};
