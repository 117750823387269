import gql from "graphql-tag";

export const GET_EXERCISES_HISTORY_PAGINATED_QUERY = gql`
  query (
    $skip: Int!
    $take: Int!
    $quizHistoryId: Int!
    $showCorrectnessOnError: Boolean!
  ) {
    getExercisesHistoryPaginated(
      data: {
        pagination: { skip: $skip, take: $take }
        quizHistoryId: $quizHistoryId
        showCorrectnessOnError: $showCorrectnessOnError
      }
    ) {
      hasMore
      total
      items {
        id
        createdAt
        earnedPoints
        maxPoints
        version
        noAnswer
        exercise {
          id
          title
          description
          errorHint
          isVocabulary
          type
          audioHidden
          audioAutoplay
          version
          audio {
            id
            fullUri
            title
          }
          dragTheWords {
            images
            text
          }
          fillInTheBlanks {
            images
            text
          }
          findSynonym {
            text
          }
          findThePlace {
            data
            text
          }
          markTheWords {
            text
          }
          multipleChoice {
            data
          }
          singleChoice {
            data
          }
        }
        dragTheWords {
          incorrect
          input
          showError
        }
        fillInTheBlanks {
          incorrect
          input
          showError
        }
        findSynonym {
          incorrect
          input
          showError
        }
        findThePlace {
          incorrect
          input
          showError
        }
        markTheWords {
          incorrect
          input
          showError
        }
        multipleChoice {
          incorrect
          input
          showError
        }
        singleChoice {
          incorrect
          input
          showError
        }
        trueFalse {
          incorrect
          input
          showError
        }
      }
    }
  }
`;
