import gql from "graphql-tag";

export const GET_CURRENT_USER_QUERY = gql`
  query {
    getCurrentUser {
      id
      firstName
      lastName
      showOnRecommendationPage
      shortAbout
      longAbout
      telNumber
      role
      interests {
        id
        title
        icon
      }
      specialities {
        id
        title
      }
      email
      profilePhotoX64 {
        photoSize
        fullUri
      }
      profilePhotoX128 {
        photoSize
        fullUri
      }
      profilePhotoX256 {
        photoSize
        fullUri
      }
      profilePhotoX512 {
        photoSize
        fullUri
      }
      speaks {
        id
        title
        icon
      }
      teaches {
        id
        icon
        title
      }
      reviewQuestions {
        id
        title
      }
      textbooks {
        id
        title
      }
      balance {
        balance
      }
    }
  }
`;
