import gql from "graphql-tag";

export const UPDATE_EXERCISE_MUTATION = gql`
  mutation UpdateExercise(
    $id: Int!
    $title: String!
    $description: String
    $errorHint: String
    $points: Float
    $audioHidden: Boolean
    $audioAutoplay: Boolean
    $dragTheWords: UpsertDragTheWordsExerciseInput
    $fillInTheBlanks: UpsertFillInTheBlanksExerciseInput
    $findSynonym: UpsertFindSynonymExerciseInput
    $findThePlace: UpsertFindThePlaceExerciseInput
    $markTheWords: UpsertMarkTheWordsExerciseInput
    $multipleChoice: UpsertMultipleChoiceExerciseInput
    $singleChoice: UpsertSingleChoiceExerciseInput
    $trueFalse: UpsertTrueFalseExerciseDataInput
  ) {
    updateExercise(
      data: {
        id: $id
        title: $title
        description: $description
        errorHint: $errorHint
        points: $points
        audioAutoplay: $audioAutoplay
        audioHidden: $audioHidden
        dragTheWords: $dragTheWords
        fillInTheBlanks: $fillInTheBlanks
        findSynonym: $findSynonym
        findThePlace: $findThePlace
        markTheWords: $markTheWords
        multipleChoice: $multipleChoice
        singleChoice: $singleChoice
        trueFalse: $trueFalse
      }
    ) {
      id
      title
      description
      createdAt
      errorHint
      isVocabulary
      order
      points
      type
      updatedAt
      version
      quizId
      dragTheWords {
        answer
        data
        audios
        images
        text
      }
      fillInTheBlanks {
        answer
        audios
        images
        text
      }
      findSynonym {
        answer
        text
      }
      findThePlace {
        answer
        data
        text
      }
      markTheWords {
        answer
        text
      }
      multipleChoice {
        answer
        data
      }
      singleChoice {
        answer
        data
      }
      trueFalse {
        answer
      }
    }
  }
`;
