import gql from "graphql-tag";

export const GET_USER_REVIEWS_PAGINATION_QUERY = gql`
  query ($userId: Int!, $skip: Int!, $take: Int!) {
    getUserReviews(
      data: { userId: $userId, pagination: { skip: $skip, take: $take } }
    ) {
      hasMore
      total
      items {
        id
        comment
        averageValue
        reviewer {
          id
          firstName
          lastName
          profilePhotoX64 {
            fullUri
          }
        }
        numberOfLessonsBetweenReviewer
        createdAt
      }
    }
  }
`;
