import { handleGqlResponseBody } from "@/utils/apollo.util";
import { toSnakeCase } from "@/utils/string";
import { GET_CHAT_LIST_PAGINATION_QUERY } from "@/graphql/queries/user/chat/get-chat-list-pagination.query";
import { GET_CHAT_MESSAGES_PAGINATION_QUERY } from "@/graphql/queries/user/chat/get_chat-messages-pagination.query";

const chat = {
  actions: {
    async getChatListQuery(_, { skip, take, orderBy, search }) {
      orderBy = orderBy.map((o) => ({
        ...o,
        fieldName: toSnakeCase(o.fieldName),
      }));

      console.log("fetching");

      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_CHAT_LIST_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            search,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getChatMessagesQuery(_, { skip, take, chatId }) {
      const orderBy = {
        direction: "Desc",
        fieldName: "created_at",
      };

      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_CHAT_MESSAGES_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            chatId,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default chat;
