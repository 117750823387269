import gql from "graphql-tag";

export const CHANGE_ORDER_LESSON_ATTACHMENT_MUTATION = gql`
  mutation ($fromId: Int!, $toId: Int!) {
    changeOrderLessonAttachment(data: { fromId: $fromId, toId: $toId }) {
      id
      fullUri
      title
      mimeType
      order
    }
  }
`;
