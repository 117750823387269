import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import router from "./router";
import store from "./store";
import VueToast from "vue-toast-notification";
import VueApollo from "vue-apollo";
import ShowGqlError from "@/plugins/show-gql-error";
import VueMoment from "vue-moment";
import VueSanitize from "vue-sanitize";

// Css styles
import "./assets/styles/styles.scss";
import "./assets/styles/wysiwyg.scss";
import "./assets/styles/landing/global.scss";
import "vue-toast-notification/dist/theme-sugar.css";
import { i18n } from "@/plugins/i18n";
import "./registerServiceWorker";

import AOS from "aos";
import "aos/dist/aos.css";
import { roundFilter } from "@/filters/math.filter";
import { apolloProvider } from "@/plugins/apollo/apollo";

// install the vue plugin
Vue.use(VueApollo);
Vue.use(VueToast, {
  position: "bottom-right",
  duration: 5000,
});
Vue.use(VueMoment);

Vue.use(VueSanitize, {});

Vue.filter("round", roundFilter);

Vue.config.productionTip = false;

Vue.use(ShowGqlError, {
  toast: Vue.prototype.$toast,
  i18n,
});

new Vue({
  created() {
    AOS.init();
  },
  router,
  store,
  vuetify,
  apolloProvider,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
