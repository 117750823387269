<template>
	<v-list-item-avatar
			:class="{ grouped: grouped }"
			:color="avatarColor"
			:size="size"
	>
		<AvatarInnerComponent
				:first-name="firstName"
				:last-name="lastName"
				:profile-image="profileImage"
				:size="size"
		/>
	</v-list-item-avatar>
</template>

<script>
import { randomAvatarColor } from "@/utils/generator";
import AvatarInnerComponent from "@/components/profile/AvatarInnerComponent";

export default {
  components: { AvatarInnerComponent },
  props: {
    size: {
      type: Number,
      required: false,
      default: null,
    },
    profileImage: { type: Object, required: false, default: null },
    firstName: {
      type: String,
      required: false,
      default: "",
    },
    lastName: {
      type: String,
      required: false,
      default: "",
    },
    grouped: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      avatarColor: randomAvatarColor(),
    };
  },
};
</script>

<style lang="scss" scoped>
.grouped {
  cursor: pointer;
  margin-left: -10px;
  border: 2px green solid;
  text-decoration: none;

  &:hover {
    z-index: 9;
  }
}
</style>
