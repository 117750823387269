<template>
	<urgent-notification ref="urgentNotification" />
</template>

<script>
import { USER_NOTIFICATION_TYPES } from "@/enums/user-notification-type.enum";
import { mapGetters } from "vuex";
import UrgentNotification from "@/components/profile/notification/urgent/UrgentNotification.vue";

export default {
  components: {
    UrgentNotification,
  },
  data() {
    return {
      openedNotificationId: null,
    };
  },
  computed: {
    ...mapGetters({
      urgentNotifications: "getUrgentNotifications",
      seenNotificationIds: "getSeenNotificationIds",
    }),
    USER_NOTIFICATION_TYPES() {
      return USER_NOTIFICATION_TYPES;
    },
    notification() {
      // console.log("this.seenNotificationIds", this.seenNotificationIds);
      const urgentNotifications = this.urgentNotifications.find(
        (x) => !this.seenNotificationIds.includes(x.id)
      );
      if (!urgentNotifications) return null;
      return urgentNotifications;
    },
  },
  watch: {
    notification: {
      handler(value) {
        setTimeout(async () => {
          if (!value || this.openedNotificationId === value.id) return;
          console.log("open");
          this.openedNotificationId = value.id;
          await this.$refs.urgentNotification.open(value);
        });
      },
      immediate: true,
    },
  },
};
</script>
