import { handleGqlResponseBody } from "@/utils/apollo.util";
import { toSnakeCase } from "@/utils/string";
import { SEARCH_USERS_PAGINATION_QUERY } from "@/graphql/queries/user/search-users-pagination.query";
import { GET_USER_MATCH_PAGINATION_QUERY } from "@/graphql/queries/user/match-instructors-pagination.query";
import { GET_USER_REVIEWS_PAGINATION_QUERY } from "@/graphql/queries/user/get-user-reviews-pagination.query";

const user = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getSearchUsersQuery(
      _,
      {
        skip,
        take,
        orderBy,
        query,
        role,
        specialityIds,
        speakIds,
        interestIds,
        learnOrTeachId,
        textbookIds,
      }
    ) {
      orderBy = orderBy.map((o) => ({
        ...o,
        fieldName: toSnakeCase(o.fieldName),
      }));
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: SEARCH_USERS_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            query,
            role,
            specialityIds,
            speakIds,
            interestIds,
            learnOrTeachId,
            textbookIds,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getMatchInstructorsQuery(
      _,
      {
        skip,
        take,
        orderBy,
        teachId,
        query,
        criteriaIds,
        interestIds,
        textbookIds,
        speakIds,
        specialityIds,
        priceFrom,
        priceTo,
      }
    ) {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_USER_MATCH_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            orderBy,
            teachId,
            query,
            criteriaIds,
            interestIds,
            speakIds,
            specialityIds,
            textbookIds,
            priceFrom,
            priceTo,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
    async getUserReviewsQuery(_, { skip, take, userId }) {
      return handleGqlResponseBody((apollo) => {
        return apollo.query({
          query: GET_USER_REVIEWS_PAGINATION_QUERY,
          variables: {
            skip,
            take,
            userId,
          },
          fetchPolicy: "no-cache",
        });
      });
    },
  },
};

export default user;
