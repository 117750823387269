export function toZPad(value, zeros = 2) {
  return `${value}`.padStart(zeros, "0");
}

export function cutValue(value, to) {
  return `${value.substring(0, to)}...`;
}

export function toSnakeCase(value) {
  const regex = new RegExp(/(?=[A-Z])/, "g");
  if (!regex.test(value)) return value;
  const splitValue = value.split(regex);
  return splitValue.join("_").toLowerCase();
}

export function hasContent(node) {
  for (let i = 0; i < node.childNodes.length; i++) {
    const child = node.childNodes[i];

    if (child.nodeType === Node.TEXT_NODE && child.textContent.trim() !== "") {
      return true;
    } else if (child.nodeName === "VIDEO") {
      return true;
    } else if (child.nodeName === "IMG") {
      return true;
    } else if (child.nodeType === Node.ELEMENT_NODE) {
      return hasContent(child);
    }
  }
}
