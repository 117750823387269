import {
  getObjectFromLocalStorage,
  setObjectToLocalStorage,
} from "@/utils/local-storage.util";

const streamVideo = {
  state: {
    usingProcesses: {
      mic: [],
      camera: [],
      screenShare: [],
    },
    isMicDeviceLoading: false,
    isCameraDeviceLoading: false,
    isScreenShareDeviceLoading: false,

    devices: {
      audio: {
        selected: {
          input: null,
        },
        input: [],
      },
      video: {
        selected: {
          input: null,
        },
        input: [],
      },
    },

    videoAvailability: true,
    audioAvailability: true,

    cameraVideoTrack: null,
    micAudioTrack: null,

    screenShareVideoTrack: null,
    screenShareAudioTrack: null,

    cameraDeviceInitError: null,
    micDeviceInitError: null,
    screenShareDeviceInitError: null,
  },
  getters: {
    getStreamVideoMicInitialized(state) {
      return state.usingProcesses.mic.length > 0;
    },
    getStreamVideoCameraInitialized(state) {
      return state.usingProcesses.camera.length > 0;
    },
    getStreamVideoScreenShareInitialized(state) {
      return state.usingProcesses.screenShare.length > 0;
    },
    getStreamVideoMicLoading(state) {
      return state.isMicDeviceLoading;
    },
    getStreamVideoCameraLoading(state) {
      return state.isCameraStreamLoading;
    },
    getStreamVideoScreenShareLoading(state) {
      return state.isScreenShareDeviceLoading;
    },
    getMicDeviceInitError(state) {
      return state.micDeviceInitError;
    },
    getCameraDeviceInitError(state) {
      return state.cameraDeviceInitError;
    },
    getScreenShareDeviceInitError(state) {
      return state.screenShareDeviceInitError;
    },
    getStreamVideoInputDevices(state) {
      return state.devices;
    },
    getStreamVideoAudioAvailability(state) {
      return state.audioAvailability;
    },
    getStreamVideoVideoAvailability(state) {
      return state.videoAvailability;
    },
    getStreamVideoCameraVideoTrack(state) {
      return state.cameraVideoTrack;
    },
    getStreamVideoMicAudioTrack(state) {
      return state.micAudioTrack;
    },
    getStreamVideoScreenShareVideoTrack(state) {
      return state.screenShareVideoTrack;
    },
    getStreamVideoScreenShareAudioTrack(state) {
      return state.screenShareAudioTrack;
    },
  },
  actions: {
    saveSelectedDevicesToLocalStore({ state }) {
      setObjectToLocalStorage("lessonInputDevicesSettings", {
        selectedDevices: {
          audio: {
            available: state.audioAvailability,
            input: state.devices.audio.selected.input,
          },
          video: {
            available: state.videoAvailability,
            input: state.devices.video.selected.input,
          },
        },
      });
    },
    restoreSelectedDevicesFromLocalStore({ state }) {
      const data = getObjectFromLocalStorage("lessonInputDevicesSettings");
      if (!data) {
        state.devices.audio.selected.input = state.devices.audio.input[0].id;
        state.devices.video.selected.input = state.devices.video.input[0].id;
        return;
      }

      state.devices.audio.selected.input = data.selectedDevices.audio.input;
      state.devices.video.selected.input = data.selectedDevices.video.input;
      state.audioAvailability = data.selectedDevices.audio.available;
      state.videoAvailability = data.selectedDevices.video.available;
    },
    async reloadInputDevices({ state }) {
      // console.log("[RUN] reloadInputDevices");
      state.devices.audio.input = [];
      state.devices.video.input = [];

      const devicesInfo = await navigator.mediaDevices.enumerateDevices();
      for (const deviceInfo of devicesInfo) {
        const device = {
          id: deviceInfo.deviceId,
          label: deviceInfo.label,
        };

        switch (deviceInfo.kind) {
          case "audioinput":
            state.devices.audio.input.push(device);
            break;
          case "videoinput":
            state.devices.video.input.push(device);
            break;
        }
      }
    },
    async startAudioInputDevice({ state, dispatch }, processId) {
      // console.log(
      //   "[STARTING] startAudioInputDevice",
      //   processId,
      //   state.usingProcesses.mic,
      //   state.micAudioTrack
      // );

      if (processId && !state.usingProcesses.mic.includes(processId))
        state.usingProcesses.mic.push(processId);
      if (!state.audioAvailability) return;

      state.isMicDeviceLoading = true;

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            deviceId: state.devices.audio.selected.input
              ? { exact: state.devices.audio.selected.input }
              : undefined,
          },
          video: false,
        });

        // Stop track first
        if (state.micAudioTrack) {
          // console.log(
          //   "[STOPPING TRACK] startAudioInputDevice",
          //   state.micAudioTrack
          // );
          state.micAudioTrack.stop();
        }

        if (stream.getAudioTracks().length) {
          stream
            .getAudioTracks()
            .forEach((track) => (state.micAudioTrack = track));
        } else {
          state.micAudioTrack = null;
        }

        // console.log(
        //   "[STARTED] startAudioInputDevice",
        //   processId,
        //   state.usingProcesses.mic,
        //   state.micAudioTrack
        // );
      } catch (e) {
        await dispatch("stopAudioInputDevice", processId);
        state.audioAvailability = false;

        if (
          e.name === "NotAllowedError" ||
          e.name === "PermissionDeniedError"
        ) {
          state.micDeviceInitError = "MIC_PERMISSION_ERROR";
        } else {
          state.micDeviceInitError = "MIC_UNAVAILABLE_ERROR";
        }

        // console.log("MIC_UNAVAILABLE_ERROR", e);
      }

      state.isMicDeviceLoading = false;
    },
    async stopAudioInputDevice({ state }, processId) {
      // console.log(
      //   "[STOPPING] stopAudioInputDevice",
      //   processId,
      //   state.usingProcesses.mic,
      //   state.micAudioTrack
      // );

      if (processId) {
        // Remove process ID
        const processIndex = state.usingProcesses.mic.indexOf(processId);
        if (processIndex > -1) state.usingProcesses.mic.splice(processIndex, 1);
        if (state.usingProcesses.mic.length > 0) return;
      }

      if (state.micAudioTrack) {
        state.micAudioTrack.stop();
        state.micAudioTrack = null;
      }

      state.micDeviceInitError = null;

      // console.log(
      //   "[STOPPED] stopAudioInputDevice",
      //   processId,
      //   state.usingProcesses.mic,
      //   state.micAudioTrack
      // );
    },
    async startCameraVideoInputDevice({ state, dispatch }, processId) {
      // console.log(
      //   "[STARTING] startCameraVideoInputDevice",
      //   processId,
      //   state.usingProcesses.camera,
      //   state.cameraVideoTrack
      // );

      if (processId && !state.usingProcesses.camera.includes(processId))
        state.usingProcesses.camera.push(processId);
      if (!state.videoAvailability) return;

      state.isCameraDeviceLoading = true;

      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: {
            deviceId: state.devices.video.selected.input
              ? { exact: state.devices.video.selected.input }
              : undefined,
          },
          audio: false,
        });

        // Stop track first
        if (state.cameraVideoTrack) {
          // console.log(
          //   "[STOPPING TRACK] startCameraVideoInputDevice",
          //   state.cameraVideoTrack
          // );
          state.cameraVideoTrack.stop();
        }

        if (stream.getVideoTracks().length) {
          stream
            .getVideoTracks()
            .forEach((track) => (state.cameraVideoTrack = track));
        } else {
          state.cameraVideoTrack = null;
        }

        // console.log(
        //   "[STARTED] startCameraVideoInputDevice",
        //   processId,
        //   state.usingProcesses.camera,
        //   state.cameraVideoTrack
        // );
      } catch (e) {
        await dispatch("stopCameraVideoInputDevice", processId);
        state.videoAvailability = false;

        if (
          e.name === "NotAllowedError" ||
          e.name === "PermissionDeniedError"
        ) {
          state.cameraDeviceInitError = "CAMERA_PERMISSION_ERROR";
        } else {
          state.cameraDeviceInitError = "CAMERA_UNAVAILABLE_ERROR";
        }

        // console.log("CAMERA_UNAVAILABLE_ERROR", e);
      }

      state.isCameraDeviceLoading = false;
    },
    async stopCameraVideoInputDevice({ state }, processId) {
      // console.log(
      //   "[STOPPING] stopCameraVideoInputDevice",
      //   processId,
      //   state.usingProcesses.camera,
      //   state.cameraVideoTrack
      // );

      if (processId) {
        // Remove process ID
        const processIndex = state.usingProcesses.camera.indexOf(processId);
        if (processIndex > -1)
          state.usingProcesses.camera.splice(processIndex, 1);
        if (state.usingProcesses.camera.length > 0) return;
      }

      if (state.cameraVideoTrack) {
        state.cameraVideoTrack.stop();
        state.cameraVideoTrack = null;
      }

      state.cameraDeviceInitError = null;

      // console.log(
      //   "[STOPPED] stopCameraVideoInputDevice",
      //   processId,
      //   state.usingProcesses.camera,
      //   state.cameraVideoTrack
      // );
    },
    // Setting device availability
    async setCameraVideoInputAvailability(
      { state, dispatch },
      { value, processId }
    ) {
      // console.log("[SET=AVAILABILITY] setCameraVideoInputAvailability", value);

      state.videoAvailability = value;
      if (value) await dispatch("startCameraVideoInputDevice", processId);
      else await dispatch("stopCameraVideoInputDevice", processId);

      // Save state
      await dispatch("saveSelectedDevicesToLocalStore");
    },
    async setMicAudioInputAvailability(
      { state, dispatch },
      { value, processId }
    ) {
      // console.log("[SET=AVAILABILITY] setMicAudioInputAvailability");

      state.audioAvailability = value;
      if (value) await dispatch("startAudioInputDevice", processId);
      else await dispatch("stopAudioInputDevice", processId);

      // Save state
      await dispatch("saveSelectedDevicesToLocalStore");
    },
    // Setting selected input device
    async setMicAudioInputDevice({ state, dispatch }, value) {
      // console.log("[CHANGE=DEVICE] setMicAudioInputDevice");

      state.devices.audio.selected.input = value;
      await dispatch("saveSelectedDevicesToLocalStore");

      await dispatch("stopAudioInputDevice", null);
      await dispatch("startAudioInputDevice", null);
    },
    async setCameraVideoInputDevice({ state, dispatch }, value) {
      // console.log("[CHANGE=DEVICE] setCameraVideoInputDevice");
      state.devices.video.selected.input = value;
      await dispatch("saveSelectedDevicesToLocalStore");

      await dispatch("stopCameraVideoInputDevice", null);
      await dispatch("startCameraVideoInputDevice", null);
    },
    async startScreenShareCapture({ state, dispatch }, processId) {
      // console.log(
      //   "[STARTING] startScreenShareCapture",
      //   state.usingProcesses.screenShare
      // );
      if (!state.usingProcesses.screenShare.includes(processId))
        state.usingProcesses.screenShare.push(processId);

      state.isScreenShareDeviceLoading = true;
      // console.log(
      //   "[STARTED] startScreenShareCapture",
      //   state.usingProcesses.screenShare
      // );

      try {
        const stream = await navigator.mediaDevices.getDisplayMedia({
          audio: {
            sampleRate: 44100,
          },
          video: {
            displaySurface: "monitor",
            cursor: "always",
          },
        });

        const onStop = () => {
          dispatch("stopScreenShareCapture");
        };
        stream.oninactive = onStop;
        stream.onended = onStop;

        stream
          .getVideoTracks()
          .forEach((track) => (state.screenShareVideoTrack = track));

        stream
          .getAudioTracks()
          .forEach((track) => (state.screenShareAudioTrack = track));
      } catch (e) {
        await dispatch("stopScreenShareCapture");
        state.screenShareDeviceInitError = "SCREEN_SHARE_UNAVAILABLE_ERROR";
        // console.log("SCREEN_SHARE_UNAVAILABLE_ERROR", e);
      }

      state.isScreenShareDeviceLoading = false;
    },
    async stopScreenShareCapture({ state }, processId) {
      // console.log(
      //   "[STOPPING] startScreenShareCapture",
      //   state.usingProcesses.screenShare
      // );
      // Remove process ID
      const processIndex = state.usingProcesses.screenShare.indexOf(processId);
      if (processIndex > -1)
        state.usingProcesses.screenShare.splice(processIndex, 1);
      if (state.usingProcesses.screenShare.length > 0) return;

      // console.log(
      //   "[STOPPED] startScreenShareCapture",
      //   state.usingProcesses.screenShare
      // );

      if (state.screenShareVideoTrack) {
        state.screenShareVideoTrack.stop();
        state.screenShareVideoTrack = null;
      }

      if (state.screenShareAudioTrack) {
        state.screenShareAudioTrack.stop();
        state.screenShareAudioTrack = null;
      }

      state.screenShareDeviceInitError = null;
    },
  },
};

export default streamVideo;
