export const roundFilter = (value, decimals = 0) => {
  if (!value) return 0; // Handle undefined/null values

  // Convert value to number and check if it's NaN
  value = parseFloat(value);
  if (isNaN(value)) return value;

  // Round the number with specified decimals
  return value.toFixed(decimals);
};
