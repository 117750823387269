<template>
	<urgent-notification-lesson-type-lesson-scheduled-action
			v-if="data.action === USER_NOTIFICATION_ACTIONS.Lesson.LessonScheduled"
			:data="data"
			@close="(x) => $emit('close', x)"
	/>
</template>
<script>
import { USER_NOTIFICATION_ACTIONS } from "@/enums/user-notification-type.enum";
import UrgentNotificationLessonTypeLessonScheduledAction from "@/components/profile/notification/urgent/type/action/UrgentNotificationLessonTypeLessonScheduledAction.vue";

export default {
  components: {
    UrgentNotificationLessonTypeLessonScheduledAction,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    USER_NOTIFICATION_ACTIONS() {
      return USER_NOTIFICATION_ACTIONS;
    },
  },
};
</script>
