<template>
	<v-app id="application">
		<v-main>
			<router-view />
		</v-main>
		<v-snackbar v-model="newVersionAvailableVisible">
			{{ $t("serviceWorker.NEW_VERSION_AVAILABLE") }}
			<template #action="{ attrs }">
				<v-btn
						color="pink"
						text
						v-bind="attrs"
						@click="reloadPage"
				>
					{{ $t("pages.common.reload") }}
				</v-btn>
			</template>
		</v-snackbar>
	</v-app>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { getFromLocalStorage } from "@/utils/local-storage.util";

// const HEARTBEAT_INTERVAL = 60000; // ms
const ORIGIN = new URL(process.env.VUE_APP_GRAPHQL_HTTP).origin;

export default {
  name: "App",
  data() {
    return {
      heartbeatInterval: null,
      newVersionAvailableVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
    }),
  },
  watch: {
    currentUser: {
      handler(value) {
        if (this.heartbeatInterval) {
          clearInterval(this.heartbeatInterval);
        }

        if (value) {
          this.heartbeatInterval = setInterval(async () => {
            const token = getFromLocalStorage("accessToken");
            await axios({
              url: `${ORIGIN}/heartbeat`, // File URL Goes Here
              method: "POST",
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
          }, 999999999);
        }
      },
      immediate: true,
    },
  },
  created() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.onmessage = (event) => {
        if (event.data && event.data.type === "NEW_VERSION_AVAILABLE") {
          this.newVersionAvailableVisible = true;
        }
      };
    }
  },
  mounted() {
    function adjustContentHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    adjustContentHeight();
    window.addEventListener("resize", adjustContentHeight);
  },
  beforeDestroy() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
    }
  },
  methods: {
    reloadPage() {
      window.location.reload();
    },
  },
};
</script>
