<template>
	<v-app class="custom-bg auth-bg">
		<v-main>
			<v-container
					fill-height
					fluid
			>
				<v-layout
						align-center
						justify-center
				>
					<v-flex
							lg7
							md8
							sm9
							xs12
					>
						<v-card
								:disabled="isLoading"
								:loading="isLoading"
								class="elevation-12"
								style="max-width: 600px"
						>
							<div class="py-4 px-2">
								<v-card-title class="logo-context">
									<logo-component
											:height="48"
											:width="120"
											color="#E98700"
									/>
								</v-card-title>

								<slot />
							</div>
						</v-card>
					</v-flex>
				</v-layout>
			</v-container>
		</v-main>
	</v-app>
</template>

<script>
import LogoComponent from "@/components/LogoComponent";

export default {
  name: "AuthLayout",
  components: { LogoComponent },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
};
</script>

<style scoped>
.auth-bg {
  background: #dbad31 !important;
  /*background-image: linear-gradient(90deg,#2dde99,#00b8b0,#6748e7);*/
}

.logo-context {
  justify-content: center;
}

.logo-title {
  padding-left: 6px;
  color: #e98700;
}
</style>
